import React, { useEffect, useState } from 'react';
import "../css/treeview.css";
import {FaRegFolder , FaRegFolderOpen , FaClipboardList } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const TreeNode = ({ node, boardNo, folders }) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [name, setName] = useState("");
    const [num, setNum] = useState(0);
    const [selected, setSelected]= useState([]);
    const navigate = useNavigate();
    const [parent, setParent] = useState("");
    const [contractList, setContractList] = useState([]);

    useEffect(()=>{
      axios.get('https://gw.thegmmedical.com:5003/api/getdatalist')
      .then(function (response){
        console.log("data.......", response.data);
        setContractList(response.data);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
      //always executed
      });
    },[]);
    const handleToggle = () => {
      setIsExpanded(!isExpanded);
    };
  

    const onNameClick = ( nodeData ) => {
      var notice_data=[];
      var board_data=[];
      const {
        // internal data
        name, num, parentNo, type
        // custom data
      } = nodeData;
      console.log("nodedata...", nodeData);

      let filtered = contractList.filter((ele) => ele.bg_no == parentNo)
      let parentName = filtered[0].bg_name;

      navigate("/contracts", {state: { boardName : name, bg_no: num, parentName : parentName}});
   
  
      boardNo({bg_no : num, bg_name : name});

      
      
  };

    return (
      <div className="tree-node">
        <div onClick={handleToggle} className={`node-toggle ${isExpanded ? 'expanded' : ''}`}>
          {(isExpanded && node.children !==undefined) &&  (<div className="tree-item" onClick={()=>onNameClick(node)}><FaRegFolderOpen /><span>{node.name}</span>
          
          {node.unread !== undefined && (<div className='unread'>{node.unread}</div>)} </div>)}
          {(!isExpanded && node.children !==undefined) &&  (<div className="tree-item" onClick={()=>onNameClick(node)}><FaRegFolder/>  <span>{node.name}</span>
          
          {node.unread !== undefined && (<div className='unread'>{node.unread}</div>)} </div>)}
          {(node.children ===undefined) &&  (<div className="tree-item" onClick={()=>onNameClick(node)}><FaClipboardList/> <span>{node.name}</span> 
          {node.unread !== undefined && (<div className='unread'>{node.unread}</div>)} </div>)}
        </div>
        {(isExpanded && node.children !==undefined) && (
          <ul className="child-nodes">
            {node.children.map(childNode => (
              <li key={childNode.id}>
                <TreeNode node={childNode} boardNo={(value)=>{boardNo(value);}}/>
                </li>
            ))}
          </ul>
        )}
      </div>
    );
  };
  
  export default TreeNode;
