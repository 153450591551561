import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Document, Page  } from 'react-pdf'
import './css/view.css';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import DataList from "../components/side/DataList";
import { GiHamburgerMenu } from "react-icons/gi";

axios.defaults.withCredentials = true; 
const initialValues = {
  year : new Date().getFullYear(),
  group : "SS"
};



const DocWrite = () => {



  const [values, setValues] = useStateWithCallbackLazy(initialValues);
  
  const [user, setUser] = useState([]);


  const [lineList, setLineList] = useState([]);
  const [lineName, setLineName] = useState("");
  const [lineNo, setLineNo] = useState(0);
  const [name, setName]= useState("");
  const [, updateState] = React.useState();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const inputFileRef = useRef();

  const { state } = useLocation();
  const [sidebar, setSidebar] = useState(false);
  const [newContractGroup, setNewContractsGroup] = useState("");

  const [contractsGroup, setContractsGroups]=useState([]);
  
  /*pdf 뷰어, 이미지 뷰어*/

  const [numPages, setNumPages] = useState(1);
  const [pdfFileList, setPdfFileList] = useState([]);
  const [imageFileList, setImageFileList] = useState([]);

  /*pdf 뷰어, 이미지 뷰어*/

  const [filterData, setfilterData] = useState([]);
  const [data, setData] = useState([]);
  
  const [main, setMain] = useState(false);
  
  const [isEmpty, setEmpty] = useState(false);
  
  const [isPage, setIsPage] = useState(false);
  
  const [notice, setNotice] = useState([]);
  
  const [boardN, setBoardN] = useState("");
  
  const [boardNum, setBoardNum] = useState();
  
  const [boardType, setBoardType] = useState("");

  let year = (new Date()).getFullYear();
  let years= [];
  for (let i=10;i>0;i--){
    years.push(year-i);
  }
  for (let i=0;i<10;i++){
    years.push(year+i);
  }
 
  const navigate = useNavigate();

  function getExtension(filename){
    var parts = filename.split('.');
    return parts[parts.length-1];
  }

  const handleSelectChange = (e) =>{
    const {name, value}= e.target;

    setValues({
      ...values,
      [name]: value
    });

    setNewContractsGroup("");
    
  }
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
};
  const fileChangedHandler = async (event) => {
  
    let pdf_list = [];
    let image_list = [];

    var newFiles = [];
    const LargeFs = [];
    var file__size=0;
    var fs = 0;
    for (let i=0;i<event.target.files.length;i++){
      let file = event.target.files[i];
      let type = file.type;
      let extension = getExtension(file.name);
      if (type.indexOf("png") || type.indexOf("jpeg")){
        let url = URL.createObjectURL(file);
        image_list.push({name : file.name, data: url});
      }

      if (extension === "pdf" || extension === "PDF"){
        let base64 = await convertBase64(file);
        pdf_list.push({name : file.name, data: base64});
      }

      file__size+=event.target.files[i].size;
      
      //filesize+=event.target.files[i].size;
      if ((fileSize+file__size)>20971520 || file__size > 20971520){
        LargeFs.push(event.target.files[i]);
        
       
      }else{
        
        fs+=event.target.files[i].size;
        newFiles.push(event.target.files[i]);
        
      }
     
    }
    setImageFileList(image_list);
    setPdfFileList(pdf_list);

    setFileSize(fileSize+fs);
    var newArray = fileList.concat(newFiles);
    setLargeFiles(LargeFs);
    setFileList(newArray);

  };
 

  const imageFileChangedHandler = async (event) => {
  
    let list = [];
    for (let i=0;i<event.target.files.length;i++){
      let file = event.target.files[i];
      //let base64 = await convertBase64(file);
      let url = URL.createObjectURL(file)
      list.push(url);
    }
    
    setImageFileList(list);
  };

  useEffect(()=>{

    console.log("state...........", state);
  
    let year="";
    let group="";
    if (state !==null){
      if(state.name!==undefined) {
        if (!isNaN(parseInt(state.name))){
          year = state.name;
          group = state.parent;
          setBoardN(state.parent + "-" + state.name);
          setValues({
            ...values,
            group: group,
            year : year
          });
          
        }else{
          group = state.name;
          setBoardN(state.name);
          setValues({
            ...values,
            group: group
          });

        }
      }
    }

    axios.get('https://gw.thegmmedical.com:5003/api/getuser')
    .then(function (response){
    setUser(response.data[0]);
    var name = response.data[0].name + " " + response.data[0].job_name+" ("+response.data[0].d_name+")";
    setName(name);
 
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });


      axios.get('https://gw.thegmmedical.com:5003/api/get_contracts_groups')
      .then(function (res){

        console.log("contact_group", res.data);
        setContractsGroups(res.data);

     
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
    

  }, []);




  const handleRegisterButton = () => {
    const formData = new FormData();
    if (values.contracts_group===undefined){
      return alert("분류를 선택해주세요.");
    }
    let contractGroupNames = contractsGroup.map(function(item){return item.name});

    if (contractGroupNames.includes(newContractGroup)){
      alert("이미 추가된 분류입니다.");
      return;
    }

    console.log("fileList......", fileList);
    
    for (let i = 0; i<fileList.length;i++){
      formData.append(`file[${i}]`, fileList[i]);
      formData.append(`filename[${i}]`, fileList[i].name);
    }
    for (let i = 0; i<largeFiles.length;i++){
      formData.append(`lfile[${i}]`, largeFiles[i]);
      formData.append(`lfilename[${i}]`, largeFiles[i].name);
    }
    formData.append(`contract_no`, values.contract_no);
    formData.append(`name`, values.name);
    formData.append(`group`, values.group);

    formData.append(`user_no`, user.user_no);
    formData.append(`user_name`, name);
    formData.append(`contracts_group`, values.contracts_group);
    formData.append(`new_contracts_group`, newContractGroup);
    formData.append(`contractor`, values.contractor);
    formData.append(`year`, values.year);


 

    const fetch = async () => {
      await axios
        .post("https://gw.thegmmedical.com:5003/api/contract_upload", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log("data.......", data);
          navigate("/contracts", {state: { boardName : values.year, parentName : values.group}});          
        });
   
    
    };

    fetch();
   



  };
  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };


  
  const [admin, setAdmin]=useState("");
  const [admins, setAdmins]=useState([]);
  

  const generateId_ad = () => {
    const highestId = Math.max.apply(Math, admins.map(function (element) {
           return element.id;
       }));
       let newId = 1; // default in case the array is empty

       if (highestId > 0) {
           // generate a new ID based off of the highest existing element ID 
           newId = (highestId + 1);
       }
       return newId;
 };

  function createNewToDoItem_ad() {
    if (admin !== '') {
      const tempStr = admin.substring(admin.indexOf("<")+1, admin.indexOf(">"));
      const item = { id: generateId_ad(), text: admin, email : tempStr  };
      console.log("item...", item);
      var valueArr = admins.map(function(item){ return item.text });
      if (valueArr.includes(admin)){
        return false;
      }
      else{
        const tempArray = [...admins, item];
        setAdmins(tempArray);
      }

      
    }
    setAdmin('');
  }

  function addAdmins() {
    console.log(admin);
    createNewToDoItem_ad();
  }
  const deleteItem_ad = id => {
    setAdmins(admins.filter(item => item.id !== id));
  };


  const display_admin = admins.map(item => (
    <div className="admin"><li key={item.id}>
      {item.text}
    </li>
    <span className="del_btn" onClick={() => deleteItem_ad(item.id)}>&times;</span>

    </div>
  ));


  
  const [rec, setRec]=useState("");
  const [recs, setRecs]=useState([]);
  

  const generateId_rec = () => {
    const highestId = Math.max.apply(Math, recs.map(function (element) {
           return element.id;
       }));
       let newId = 1; // default in case the array is empty

       if (highestId > 0) {
           // generate a new ID based off of the highest existing element ID 
           newId = (highestId + 1);
       }
       return newId;
 };

  function createNewToDoItem_rec() {
    if (rec !== '') {
      const tempStr = rec.substring(rec.indexOf("<")+1, rec.indexOf(">"));
      const item = { id: generateId_rec(), text: rec, email : tempStr, check : false  };
      console.log("item...", item);
      var valueArr = recs.map(function(item){ return item.text });
      if (valueArr.includes(rec)){
        return false;
      }
      else{
        const tempArray = [...recs, item];
        setRecs(tempArray);
      }

      
    }
    setRec('');
  }

  function addRecs() {
    console.log(rec);
    createNewToDoItem_rec();
  }
  const deleteItem_rec = id => {
    setRecs(recs.filter(item => item.id !== id));
  };


  const display_rec = recs.map(item => (
    <div className="admin"><li key={item.id}>
      {item.text}
    </li>
    {item.preset !==true && (
    <span className="del_btn" onClick={() => deleteItem_rec(item.id)}>&times;</span>
    )}

    </div>
  ));

  const [cc, setCc]=useState("");
  const [ccs, setCcs]=useState([]);
  

  const generateId_cc = () => {
    const highestId = Math.max.apply(Math, ccs.map(function (element) {
           return element.id;
       }));
       let newId = 1; // default in case the array is empty

       if (highestId > 0) {
           // generate a new ID based off of the highest existing element ID 
           newId = (highestId + 1);
       }
       return newId;
 };

  function createNewToDoItem_cc() {
    if (cc !== '') {
      const tempStr = cc.substring(cc.indexOf("<")+1, cc.indexOf(">"));
      const item = { id: generateId_cc(), text: cc, email : tempStr, check : false  };
      console.log("item...", item);
      var valueArr = ccs.map(function(item){ return item.text });
      if (valueArr.includes(cc)){
        return false;
      }
      else{
        const tempArray = [...ccs, item];
        setCcs(tempArray);
      }

      
    }
    setCc('');
  }

  function addCcs() {
    console.log(rec);
    createNewToDoItem_cc();
  }
  const deleteItem_cc= id => {
    setCcs(ccs.filter(item => item.id !== id));
  };


  const display_cc = ccs.map(item => (
    <div className="admin"><li key={item.id}>
      {item.text}
    </li>
    {item.preset !==true && (
    <span className="del_btn" onClick={() => deleteItem_cc(item.id)}>&times;</span>
    )};
    </div>
  ));




  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  window.addEventListener('message', (event) => {
    if (event.data["line_no"] !== undefined){
      var line_no = event.data["line_no"];
      var line_name = event.data["line_name"];
      var line_list = event.data["line_list"];
      
      setLineNo(line_no);
      setLineName(line_name);
      setLineList(line_list);
    }

   
  });
////////////////////////////////////////////////
// EXCEL IMPORT
////////////////////////////////////////////////

function delLine(){
  setLineList([]);
  setLineName("");
  setLineNo();
}

const onBtnClick = () => {
  /*Collecting node-element and performing click*/
  inputFileRef.current.click();
};
function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes'

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const removeFile = (i) => {

  let remove_file = fileList.filter((_, index)=> index === i);
  let remove_name = remove_file[0].name;
  
  setFileList([...fileList.filter((_, index) => index !== i)]);
  setPdfFileList([...pdfFileList.filter((file, index)=> file.name !== remove_name)]);
  setImageFileList([...imageFileList.filter((file, index)=> file.name !== remove_name)]);

  setFileSize(fileSize-fileList[i].size);
};





  return (
    <div className="wrapper">

    <div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
    </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <DataList changeBoard={(value)=>{
  console.log("boardvalue,,,", value);
    setfilterData(value);setData(value);setMain(false);
  
  
        if (value.length === 0) {
          setEmpty(true);
        } else {
          setEmpty(false);
          if (value.length <= 10) {
            setIsPage(false);
          } else {
            setIsPage(true);
          }
        }}} changeNotice={(value)=>{setNotice(value);}} changeBoardN={(value)=>{setBoardN(value);}} 
        changeBoardNum={(value)=>{
          setBoardNum(value);setSidebar(false);
          axios.post('https://gw.thegmmedical.com:5003/api/get_dataadmin', {
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              bg_no : value
            },
          })
          .then(function (response){
            console.log("admin is", response.data);
            if (response.data[0].board_type !== null){
            setBoardType(response.data[0].board_type);
            }
            let adms = response.data;
            adms = adms.map((ele,idx)=>{return ele.admin_email});
            console.log("adms", adms);
            setAdmins(adms);
          })
          .catch(function (error){
          console.log(error);
          })
          .then(function (){
          //always executed
          });
        }}
        
        
        
        />
        </div>
      )}
<div className="content-wrap">




    <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>
    <div className="side-menu"> 
    <DataList changeBoard={(value)=>{setfilterData(value);setData(value);setMain(false);
        if (value.length === 0) {
          setEmpty(true);
        } else {
          setEmpty(false);
          if (value.length <= 10) {
            setIsPage(false);
          } else {
            setIsPage(true);
          }
        }}} changeNotice={(value)=>{setNotice(value);}} changeBoardN={(value)=>{console.log("boardN", value);setBoardN(value);}} 
        changeBoardNum={(value)=>{
          console.log("boardNum", value);setBoardNum(value);
          axios.post('https://gw.thegmmedical.com:5003/api/get_dataadmin', {
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              bg_no : value
            },
          })
          .then(function (response){
            console.log("admin is", response.data);
            if (response.data[0].board_type !== null){
            setBoardType(response.data[0].board_type);
            }
            let adms = response.data;
            adms = adms.map((ele,idx)=>{return ele.admin_email});
            console.log("adms", adms);
            setAdmins(adms);
          })
          .catch(function (error){
          console.log(error);
          })
          .then(function (){
          //always executed
          });

        }}/>
    </div>
    

    <div className="docwrite main_content">

      <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
      <h4> 계약서 업로드 </h4>

      

        
            
          </div>
          <div style={{background:"#fff", padding:"0"}}>
        
          <div style={{display:"flex", width:"100%", justifyContent:"space-between", flexDirection:"column"}}>
          

         
<table className="form_write" style={{margin:"10px 0", width:"100%"}}>
<tr>
<td class="table_title2" nowrap="">
  계약분류
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <select name="contracts_group" className="formInput" value={values.contracts_group ||''}
    onChange={handleSelectChange}>    
    <option value="">분류 선택</option>
    {contractsGroup.map((element, index)=>(
      <option value={element.no} className={element.no}>{element.name}</option>
    ))}
    <option value="0" className="0">새 분류 추가</option>

  </select>

  {values.contracts_group == "0" && (
    <input type="text" value={newContractGroup} onChange={(e)=>setNewContractsGroup(e.target.value)}
    style={{border:"1px solid #333", maxWidth:"200px"}}></input>
  )}
 
  </div>
  </td>




</tr>


<tr>
<td class="table_title2" nowrap="">
  계약명
</td>
  <td class="table_list2_left">
  <div className="input-group">
    <input type="text" name="name" className="formInput" placeholder="계약명을 입력하세요." value={values.name}
      onChange={handleInputChange}>
    </input>
  </div>
  </td>

</tr>      

<tr>
<td class="table_title2" nowrap="">
  계약 회사
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <select name="group" value={values.group || ``} onChange={handleSelectChange}>
      <option value="SS">
        생생메디칼
      </option>
      <option value="GM">
        GM메디칼
      </option>
    </select>
  </div>
  </td>

</tr>


<tr>
<td class="table_title2" nowrap="">
  계약 상대
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <input type="text" name="contractor" className="formInput" placeholder="계약 상대를 입력하세요." value={values.contractor}
      onChange={handleInputChange}>
  </input>  
  </div>
  </td>

</tr>

<tr>
<td class="table_title2" nowrap="">
  계약 년도
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <select name="year" className="formInput" value={values.year || ``} onChange={handleSelectChange}>
      {years.map((year, index)=>{
        return <option key={`year${index}`} value={year}>{year}</option>
      })}
    </select>
  </div>
  </td>

</tr>


  <tr>
  <td class="table_title2" nowrap="">
    첨부파일
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <input type="file" id="files" name="files" style={{display:"none"}} ref={inputFileRef} onChange={fileChangedHandler} multiple/>
    <button style={{cursor:'pointer'}} className="large_file_btn" onClick={()=>onBtnClick()}>
      파일 첨부
    </button>
    
    </div>
 
    </td>
  </tr>
  <tr style={{height:"auto"}}>
  <td class="table_title2" nowrap="">
    
  </td>
    <td class="upload_file_list">
  {fileList.map((file,index) => 
    <div className="file_item">
      <p>{file.name} {formatBytes(file.size)}</p>
      <button type="button" className="remove" onClick={() => {removeFile(index);}}>x</button>
    </div>
    
  )} 

{largeFiles.map((file,index) => 
    <div>
      <p>{file.name} {formatBytes(file.size)} <span>다운로드 가능 기간</span></p>
      
      <button type="button" onClick={() => {removeFile(index);}}>Remove</button>
    </div>
    
  )} 
    </td>
  </tr>



    </table>

  <div>

{pdfFileList.length>0 && (
pdfFileList.map((file,index) => 
<div className="file_item" style={{border:"1px solid #ddd", marginBottom:"5px"}}>
{
        <Document file={file.data} onLoadSuccess={({ numPages })=>setNumPages(numPages)}>
        
        {Array.apply(null, Array(numPages))
    .map((x, i)=>i+1)
    .map(page => <Page pageNumber={page}/>)}

        </Document>

}
</div>
))}

{imageFileList.length>0 && (
imageFileList.map((file,index) => 
<div className="file_item" style={{border:"1px solid #ddd", marginBottom:"5px"}}>
{
  <img src={file.data} alt={file.data}></img>

}
</div>
))}
   
  </div>









  </div>

   

    </div>


    

    <div className="button_wrap" style={{margin:"20px 0"}}>
    <button onClick={handleRegisterButton}>등록</button>
    
    {/*</form>*/}
    </div>
    </div>
  </div>
  </div>
  </div>

  );
};

export default DocWrite;
