import React, { useState, useEffect } from "react";
import "react-tooltip/dist/react-tooltip.css";


import "./css/popup.css";
import axios from "axios";
import moment from "moment";



const LeaveHistoryUser = ({ closePopup, userno,year }) => {
  
  const [data, setData] = useState([]);
  const week = ["일","월","화","수","목","금","토"];

  useEffect(() => {
    axios.get("https://gw.thegmmedical.com:5003/api/getleavehistory_user/"+userno+"/"+year).then((response) => {
        console.log(response.data);
        setData(response.data);
      });
  }, []);

  
  return (
    <div className="popup">
      <div className="inner" style={{minWidth:"800px", height:"auto", maxHeight:"500px", position:"relative",
      left:"50%", transform:"translateX(-50%) translateY(-50%)", top:"50%", maxWidth:"1000px"}}>
      <table width="100%" cellspacing="0" cellpadding="0" border="0" bordercolor="black">
    	<tbody>
           
            <tr height="23">
            <th nowrap="" class="table_title">휴가일자</th>

                <th nowrap="" class="table_title">차감 일수</th>
                <th nowrap="" class="table_title">구분</th>

                <th nowrap="" class="table_title">결재상태</th>
                <th nowrap="" class="table_title_rno">비고</th>
            </tr> 
            {data.map((element,index)=>(
                  <tr height="23">
                  
                  <td nowrap="" class="table_title">{moment(element.start_date).format('YYYY-MM-DD')} ({week[new Date(element.start_date).getDay()]}) ~ {moment(element.end_date).format('YYYY-MM-DD')} ({week[new Date(element.end_date).getDay()]})</td>
                  <td nowrap="" class="table_title">{element.days}</td>
                  <td nowrap="" class="table_title">
                  {element.vtype==="1" && (
      <span>연차</span>
    )}
     {element.vtype==="0.5" && (
      <span>{element.vrange == "2" ? "오후 " : "오전 "}반차</span>
    )}
    {element.vtype==="0.25" && (
      <span>반반차</span>
    )}
      {element.vtype==="0" && (
      <span>공가</span>
    )}

                  </td>
                  <td nowrap="" class="table_title">{element.approval}</td>
                  <td nowrap="" class="table_title_rno">{element.reason} {element.contact}</td>
              </tr> 
            ))}
     
     {data.length===0 && (
              <tr>
                <td colSpan={5}>휴가 기록이 없습니다.</td>
              </tr>
            )}
     




    </tbody></table>
    
        <button style={{marginTop:"20px", border:"1px solid #ddd"}} className="btn" onClick={closePopup}>닫기</button>
      </div>
      
    </div>
  );
};

export default LeaveHistoryUser;