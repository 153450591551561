
import { NavLink, Link } from "react-router-dom";
import * as XLSX from "xlsx-js-style";
import moment from "moment";

import React, { useState, useEffect, useRef } from "react";
import Popup from "../components/Popup";
import axios from "axios";
import "./css/adminUsers.css";
import Pagination from "./Pagination";
import { RxHamburgerMenu } from "react-icons/rx";
import Sidebar from "../components/side/MobileSidebar";
const AdminUsers = () => {
    const [cnt, setCnt] = useState(0);
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState([]);
    const [showPopup, setPopup] = useState(false);
    const [showCreate, setCreate] = useState(false);
    const [checkList, setCheckList] = useState([]);
    const [sidebar, setSidebar] = useState(false);
    const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [experiences, setExperiences] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [educations, setEducations] = useState([]);
  const [vacations, setVacations] = useState([]);
  const [bzTrips, setBzTrips] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [salaries, setSalaries] = useState([]);
  const [languages, setLanguages] = useState([]);

  const offset = (page - 1) * limit;


   function getVType(input){
      if (input==="0.5"){
        return "반차";
      }else if (input==="1"){
        return "연차";
      }else{
        return "공가"
      }
    }


    const togglePopup = () => {
        setPopup(!showPopup);
        

    };
    const toggleCreate = () => {
      setCreate(!showCreate);
      

  };
    const updateUser = () => {
      console.log("checklist...", checkList);
        for (var i=0;i<data.length;i++){
          if (data[i].email === checkList[0]){
            setSelected(data[i]);
          }
        }
      setPopup(!showPopup);
      
    };
    const deleteUser = () => {
      console.log(checkList);
      var array = []
      var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')
  
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].value!=="selectall"){
        array.push(checkboxes[i].value)
        }
      }
      console.log(array);
      if (array[0].value==="selectall"){
        array.shift();
      }
      const data ={
        nos : array
      }
      var result = window.confirm("선택된 사용자를 삭제하시겠습니까?");
      if(result){
        axios.post('https://gw.thegmmedical.com:5003/api/deleteUsers', data)
        .then(function (response){
          console.log(response);
          
          getUser();
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };
    const stopUser = () => {
      console.log(checkList);
      var array = []
      var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')
  
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].value!=="selectall"){
        array.push(checkboxes[i].value)
        }
      }
      console.log(array);
      if (array[0].value==="selectall"){
        array.shift();
      }
      const data ={
        nos : array
      }
      var result = window.confirm("선택된 사용자를 정지하시겠습니까?");
      if(result){
        axios.post('https://gw.thegmmedical.com:5003/api/stopUsers', data)
        .then(function (response){
          console.log(response);
          getUser();
     
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };
    const activeUsers = () => {
      console.log(checkList);
      var array = []
      var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')
  
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].value!=="selectall"){
        array.push(checkboxes[i].value)
        }
      }
      console.log(array);
      if (array[0].value==="selectall"){
        array.shift();
      }
      const data ={
        nos : array
      }
      var result = window.confirm("선택된 사용자를 활성화하시겠습니까?");
      if(result){
        axios.post('https://gw.thegmmedical.com:5003/api/activateUsers', data)
        .then(function (response){
          console.log(response);
          getUser();
     
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };
    function getUser(){
      fetch("https://gw.thegmmedical.com:5003/api/getusers/", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        setCnt(json[0].cnt);
        setData(json);
        
      })
      .catch(() => {});
    
    }
    useEffect(() => {
       
      getUser();
        
     
        



      fetch("https://gw.thegmmedical.com:5003/api/get_experience", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log("experience", json);  


        setExperiences(json);
 
        //console.log(boxes.boxes);
        //console.log(boxes.boxes);
      })
      .catch(() => {});




      fetch("https://gw.thegmmedical.com:5003/api/get_certificate", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log("experience", json);  


        setCertificates(json);
 
        //console.log(boxes.boxes);
        //console.log(boxes.boxes);
      })
      .catch(() => {});


      fetch("https://gw.thegmmedical.com:5003/api/get_language", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log("experience", json);  


        setLanguages(json);
 
        //console.log(boxes.boxes);
        //console.log(boxes.boxes);
      })
      .catch(() => {});


      fetch("https://gw.thegmmedical.com:5003/api/get_education", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log("", json);  


        setEducations(json);
 
        //console.log(boxes.boxes);
        //console.log(boxes.boxes);
      })
      .catch(() => {});
     
      fetch("https://gw.thegmmedical.com:5003/api/get_bztrip", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log("experience", json);  


        setBzTrips(json);
 
        //console.log(boxes.boxes);
        //console.log(boxes.boxes);
      })
      .catch(() => {});
   
      fetch("https://gw.thegmmedical.com:5003/api/get_salary", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log("experience", json);  


        setSalaries(json);
 
        //console.log(boxes.boxes);
        //console.log(boxes.boxes);
      })
      .catch(() => {});
      fetch("https://gw.thegmmedical.com:5003/api/get_vacations", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log("experience", json);  


        setVacations(json);
 
        //console.log(boxes.boxes);
        //console.log(boxes.boxes);
      })
      .catch(() => {});


      fetch("https://gw.thegmmedical.com:5003/api/get_appointment", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log("experience", json);  


        setAppointments(json);
 
        //console.log(boxes.boxes);
        //console.log(boxes.boxes);
      })
      .catch(() => {});






    
      
      }, []);
      function selectAll()  {
        const checkboxes 
             = document.getElementsByName('animal');
        const all 
             = document.getElementsByName('animalall');
        //console.log(all[0].checked)
        checkboxes.forEach((checkbox) => {
          checkbox.checked = all[0].checked;
        })
      }

      const onChangeEach = (e, id) => {
        // 체크할 시 CheckList에 id값 넣기
        if (e.target.checked) {
           setCheckList([...checkList, id]);
        // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
        } else {
           setCheckList(checkList.filter((checkedId) => checkedId !== id));
        }
        
     }

     function exportExcel(){
      console.log("게정정보", data[0]);
      const wb= XLSX.utils.book_new();


   


      let newJson = data.map(ele => {
        return {이름: ele.name, 부서: ele.d_name, 직급: ele.job_name, 핸드폰: ele.tel, 이메일:ele.email,
          fax: ele.fax, 내선번호: ele.extension, 생년월일: ele.birthdate, 입사일자: ele.start, 퇴사일자: ele.resig,
          퇴사사유:ele.resig_reason, 담당업무: ele.work_name, '현직급 승진일': ele.promote_date, 입사경로: ele.howToEnter,
          '추천인/관계':ele.referee, 주소: ele.addr
        }
       
      });
      // json_to_sheet 방식으로 데이터 생성
      const firstSheet = XLSX.utils.json_to_sheet(newJson, {header :["이름", "부서", "직급", "핸드폰", "이메일", "fax",
        "내선번호", "생년월일", "입사일자", "퇴사일자", "퇴사사유", "담당업무", "현직급 승진일", "입사경로", "추천인/관계", "주소"
      ],
      skipHeader: false}); // skipHeader가 false이면 엑셀 시트의 첫 번째 행에 header에 해당하는 A, B, C 가 삽입
      // 칼럼 넓이 지정
      firstSheet["!cols"] = [
        { wpx : 80 }, // A열
        { wpx : 80 }, // B열
        { wpx : 80 }, // C열
        { wpx : 90 }, // D열
        { wpx : 160 }, // D열
        { wpx : 80 }, // D열
        { wpx : 120 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 150 }, // D열


      ];
      firstSheet["A1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      firstSheet["B1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      firstSheet["C1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      firstSheet["D1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      firstSheet["E1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      firstSheet["F1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      firstSheet["G1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      firstSheet["H1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      firstSheet["I1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      firstSheet["J1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      firstSheet["K1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      firstSheet["L1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      firstSheet["M1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      firstSheet["N1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      firstSheet["O1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      firstSheet["P1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      // 첫 번째 시트에 작성한 데이터를 넣는다.
      XLSX.utils.book_append_sheet(wb, firstSheet, "인적사항");
     
      let userno=0;


      let experison_json = experiences.map(ele => {
        if (userno!==ele.user_no){
          userno = ele.user_no;


        return {'이름': ele.name,
            '부서': ele.d_name,
            '현재 직위(급)': ele.job_name,
            '시작일': ele.start,
            '종료일': ele.end,
            '회사명': ele.company,
            '직위(급)': ele.job,
            '업무 내용': ele.content,
            '퇴사 사유': ele.resign
        }
      }else{
        return { '시작일': ele.start,
          '종료일': ele.end,
          '회사명': ele.company,
          '직위(급)': ele.job,
          '업무 내용': ele.content,
          '퇴사 사유': ele.resign}
      }
       
      });




      const secondSheet = XLSX.utils.json_to_sheet(experison_json, {header :["이름", "부서", "현재 직위(급)", "시작일",
        "종료일", "회사명", "직위(급)", "업무 내용", "퇴사 사유"
      ],
      skipHeader: false});
     


      secondSheet["!cols"] = [
        { wpx : 80 }, // A열
        { wpx : 80 }, // B열
        { wpx : 80 }, // C열
        { wpx : 90 }, // D열
        { wpx : 160 }, // D열
        { wpx : 80 }, // D열
        { wpx : 120 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 } // D열
       


      ];
      secondSheet["A1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      secondSheet["B1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      secondSheet["C1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      secondSheet["D1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      secondSheet["E1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      secondSheet["F1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      secondSheet["G1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      secondSheet["H1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      secondSheet["I1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      XLSX.utils.book_append_sheet(wb, secondSheet, "경력사항");


      userno=0;
      let certificates_json = certificates.map(ele => {
        if (userno!==ele.user_no){
          userno = ele.user_no;


        return {'이름': ele.name,
            '부서': ele.d_name,
            '현재 직위(급)': ele.job_name,
            '자격 및 면허명': ele.name,
            '취득년월일': ele.acquired_date,
            '인정기관': ele.organization
        }
      }else{
        return { '자격 및 면허명': ele.name,
            '취득년월일': ele.acquired_date,
            '인정기관': ele.organization
        }
      }
       
      });






      const thirdSheet = XLSX.utils.json_to_sheet(certificates_json, {header :["이름", "부서", "현재 직위(급)", "자격 및 면허명",
        "취득년월일", "인정기관"
      ],
      skipHeader: false});
     


      thirdSheet["!cols"] = [
        { wpx : 80 }, // A열
        { wpx : 80 }, // B열
        { wpx : 80 }, // C열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 } // D열
   


      ];
      thirdSheet["A1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      thirdSheet["B1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      thirdSheet["C1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      thirdSheet["D1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      thirdSheet["E1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      thirdSheet["F1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
 
      XLSX.utils.book_append_sheet(wb, thirdSheet, "자격증");
      userno=0;
      let languages_json = languages.map(ele => {
        if (userno!==ele.user_no){
          userno = ele.user_no;


        return {'이름': ele.name,
            '부서': ele.d_name,
            '현재 직위(급)': ele.job_name,
            '언어': ele.name,
            '공인시험': ele.test,
            '점수': ele.score,
            '시험날짜': ele.testDate,
            '회화능력': ele.speaking,
            '작문능력': ele.writing


        }
      }else{
        return { '언어': ele.name,
            '공인시험': ele.test,
            '점수': ele.score,
            '시험날짜': ele.testDate,
            '회화능력': ele.speaking,
            '작문능력': ele.writing
        }
      }
       
      });






      const fourthsheet = XLSX.utils.json_to_sheet(languages_json, {header :["이름", "부서", "현재 직위(급)", "언어",
        "공인시험", "점수", "시험날짜", "회화능력", "작문능력"
      ],
      skipHeader: false});
     


      fourthsheet["!cols"] = [
        { wpx : 80 }, // A열
        { wpx : 80 }, // B열
        { wpx : 80 }, // C열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 } // D열


      ];
      fourthsheet["A1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      fourthsheet["B1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      fourthsheet["C1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      fourthsheet["D1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      fourthsheet["E1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      fourthsheet["F1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      fourthsheet["G1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      fourthsheet["H1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      fourthsheet["I1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      XLSX.utils.book_append_sheet(wb, fourthsheet, "어학");
     
      userno=0;
      let educations_json = educations.map(ele => {
        if (userno!==ele.user_no){
          userno = ele.user_no;


        return {'이름': ele.name,
            '부서': ele.d_name,
            '현재 직위(급)': ele.job_name,
            '교육명': ele.name,
            '교육기관': ele.institution,
            '교육내용': ele.content,
            '비용': ele.cost,
            '시작일': ele.startDate,
            '종료일': ele.endDate


        }
      }else{
        return {  '교육명': ele.name,
          '교육기관': ele.institution,
          '교육내용': ele.content,
          '비용': ele.cost,
          '시작일': ele.startDate,
          '종료일': ele.endDate
        }
      }
       
      });
      const fifthsheet = XLSX.utils.json_to_sheet(educations_json, {header :["이름", "부서", "현재 직위(급)", "교육명",
        "교육기관", "교육내용", "비용", "시작일", "종료일"
      ],
      skipHeader: false});
     


      fifthsheet["!cols"] = [
        { wpx : 80 }, // A열
        { wpx : 80 }, // B열
        { wpx : 80 }, // C열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 } // D열


      ];
      fifthsheet["A1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      fifthsheet["B1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      fifthsheet["C1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      fifthsheet["D1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      fifthsheet["E1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      fifthsheet["F1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      fifthsheet["G1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      fifthsheet["H1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      fifthsheet["I1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      XLSX.utils.book_append_sheet(wb, fifthsheet, "교육");
     
      userno=0;
      let bztrip_json = bzTrips.map(ele => {
        if (userno!==ele.user_no){
          userno = ele.user_no;


        return {'이름': ele.name,
            '부서': ele.d_name,
            '현재 직위(급)': ele.job_name,
            '출장': ele.name,
            '시작일': ele.start,
            '종료일': ele.end,
            '사유': ele.reason,
            '국가': ele.country,
            '비용': ele.cost


        }
      }else{
        return { '출장': ele.name,
            '시작일': ele.start,
            '종료일': ele.end,
            '사유': ele.reason,
            '국가': ele.country,
            '비용': ele.cost
        }
      }
       
      });


      const sixthsheet = XLSX.utils.json_to_sheet(bztrip_json, {header :["이름", "부서", "현재 직위(급)", "출장",
        "시작일", "종료일", "사유", "국가", "비용"
      ],
      skipHeader: false});
     


      sixthsheet["!cols"] = [
        { wpx : 80 }, // A열
        { wpx : 80 }, // B열
        { wpx : 80 }, // C열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 } // D열
   


      ];
      sixthsheet["A1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      sixthsheet["B1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      sixthsheet["C1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      sixthsheet["D1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      sixthsheet["E1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      sixthsheet["F1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      sixthsheet["G1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      sixthsheet["H1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      sixthsheet["I1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      XLSX.utils.book_append_sheet(wb, sixthsheet, "출장");
     
      userno=0;
      let salaries_json = salaries.map(ele => {
        if (userno!==ele.user_no){
          userno = ele.user_no;


        return {'이름': ele.name,
            '부서': ele.d_name,
            '현재 직위(급)': ele.job_name,
            '금액': ele.amount,
            '시작일자': ele.start,
            '사유': ele.reason,
            '내용': ele.content


        }
      }else{
        return {  '금액': ele.amount,
          '시작일자': ele.start,
          '사유': ele.reason,
          '내용': ele.content
        }
      }
       
      });


      const seventhsheet = XLSX.utils.json_to_sheet(salaries_json, {header :["이름", "부서", "현재 직위(급)", "금액",
        "시작일자", "사유", "내용"
      ],
      skipHeader: false});
     


      seventhsheet["!cols"] = [
        { wpx : 80 }, // A열
        { wpx : 80 }, // B열
        { wpx : 80 }, // C열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 }
 
   


      ];
      seventhsheet["A1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      seventhsheet["B1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      seventhsheet["C1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      seventhsheet["D1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      seventhsheet["E1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      seventhsheet["F1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      seventhsheet["G1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
     
      XLSX.utils.book_append_sheet(wb, seventhsheet, "급여");
      userno=0;
      let vacations_json
      if (vacations.length>0){
        vacations_json = vacations.map(ele => {
          if (userno!==ele.user_no){
            userno = ele.user_no;
 
          return {'이름': ele.name,
              '부서': ele.d_name,
              '현재 직위(급)': ele.job_name,
              '구분': getVType(ele.vtype),
              '시작일': moment(ele.start_date).format("YYYY-MM-DD"),
              '종료일': moment(ele.start_date).format("YYYY-MM-DD"),
              '일수': parseFloat(ele.vtype) * ele.days,
              '사유': ele.reason,
              '비고': ele.memo
 
          }
        }else{
          return {  '구분': getVType(ele.vtype),
              '시작일': moment(ele.start_date).format("YYYY-MM-DD"),
              '종료일': moment(ele.start_date).format("YYYY-MM-DD"),
              '일수': ele.vtype * ele.days,
              '사유': ele.reason,
              '비고': ele.memo
          }
        }
         
        });
      }else{
        vacations_json = vacations.map(ele => {
          if (userno!==ele.user_no){
            userno = ele.user_no;
 
          return {'이름': ele.name,
              '부서': ele.d_name,
              '현재 직위(급)': ele.job_name,
              '구분': "",
              '시작일': "",
              '종료일': "",
              '일수': "",
              '사유': ele.reason,
              '비고': ele.memo
 
          }
        }else{
          return {   '구분': "",
            '시작일': "",
            '종료일': "",
            '일수': "",
            '사유': ele.reason,
            '비고': ele.memo
          }
        }
         
        });
      }
     


      const eightsheet = XLSX.utils.json_to_sheet(vacations_json, {header :["이름", "부서", "현재 직위(급)", "구분",
        "시작일", "종료일", "일수", "사유", "비고"
      ],
      skipHeader: false});
     


      eightsheet["!cols"] = [
        { wpx : 80 }, // A열
        { wpx : 80 }, // B열
        { wpx : 80 }, // C열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 } // D열
   


      ];
      eightsheet["A1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      eightsheet["B1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      eightsheet["C1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      eightsheet["D1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      eightsheet["E1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      eightsheet["F1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      eightsheet["G1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      eightsheet["H1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      eightsheet["I1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      XLSX.utils.book_append_sheet(wb, eightsheet, "휴가");
      userno=0;
      let appointments_json = appointments.map(ele => {
        if (userno!==ele.user_no){
          userno = ele.user_no;


        return {'이름': ele.name,
            '부서': ele.d_name,
            '현재 직위(급)': ele.job_name,
            '소속': ele.current,
            '직급': ele.job,
            '발령일자': ele.appoint_date,
            '발령사항': ele.appoint,
            '비고': ele.memo


        }
      }else{
        return {  '소속': ele.current,
            '직급': ele.job,
            '발령일자': ele.appoint_date,
            '발령사항': ele.appoint,
            '비고': ele.memo
        }
      }
       
      });


      const ninethsheet = XLSX.utils.json_to_sheet(appointments_json, {header :["이름", "부서", "현재 직위(급)", "소속",
        "직급", "발령일자", "발령사항", "비고"
      ],
      skipHeader: false});
     


      ninethsheet["!cols"] = [
        { wpx : 80 }, // A열
        { wpx : 80 }, // B열
        { wpx : 80 }, // C열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 }, // D열
        { wpx : 80 }
   


      ];
      ninethsheet["A1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      ninethsheet["B1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      ninethsheet["C1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      ninethsheet["D1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      ninethsheet["E1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      ninethsheet["F1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      ninethsheet["G1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
      ninethsheet["H1"].s = {
        fill: {
          patternType : "solid",
          fgColor: {rgb: "FFC1DFF6"},
          bgColor: { rgb: "FFC1DFF6"},
        },
      };
   
      XLSX.utils.book_append_sheet(wb, ninethsheet, "인사발령");
      XLSX.writeFile(wb, "인사관리.xlsx");
    }
   




  return (
    <div className="wrapper" style={{width:"100%",backgroundColor:"#f7f7f7", minHeight:"100vh"}}>
    {sidebar && ( <Sidebar style={{display:"block"}}/>)}
     
    <div className="menu_icon" style={{width:"100%", height:"30px", zIndex:"1000", position:"relative"}}>
      <RxHamburgerMenu className={(sidebar ? "active" : "")} onClick={()=>setSidebar(!sidebar)} style={{zIndex:"1000"}}/>
      </div>
     <div className="contents" style={{maxWidth:"1600px"}}>
        <div className="header_txt">
        <h3>사용자 관리</h3>
        </div>
        <div className="contents_top">

            <table className="">
                <tr>
                    <td> 현재 사원 수 </td>
                    <td><strong>{cnt}</strong>명 </td>
                    <td> 정상({cnt}명), 중지된 계정 ({data.filter((item) => {return item.inuse == 0}).length})개 </td>
                </tr>
            </table>
        </div>

        <div className="main_contents_wrapper">
            <div className="btns" style={{display:"flex", justifyContent:"space-between"}}>
              <div className="left">

              
                <button onClick={() => {setSelected();togglePopup();}}>계정 생성</button> 
                {checkList.length===1 ? (<button onClick={() => updateUser()}>정보 변경</button> 
                ): (<button disabled onClick={() => updateUser()}>정보 변경</button> 
                )}
                {/*<button onClick={() => deleteUser()}>계정 삭제</button>*/}
              </div>

              <div className="right">
                {/*<button onClick={() => stopUser()}>사용자 중지</button> 
                <button onClick={() => activeUsers()}>계정 활성화</button> */}
                <button onClick={() => exportExcel()}>엑셀로 내보내기</button> 

            </div>
            </div>

{showCreate ? (
  <Popup text="Title" closePopup={() => {toggleCreate();getUser();}} />
) : null}           

{showPopup ? (
  <Popup member={selected} text="Title" closePopup={() => {togglePopup();getUser();}} />
) : null}

<div className="table_wrap">
<table class="fixed_headers responsive">
  <thead>
    <tr>
    <th className="check">
    <input type='checkbox'
       name='animalall' 
       value='selectall'
       onClick={selectAll}/>

    </th>
      <th>재직</th>
      <th>이름</th>
      <th>아이디</th>
      <th>부서</th>
      <th>직위</th>
      <th>입사일자</th>
      <th>상태</th>

    </tr>
  </thead>
  <tbody>
  
  { data.map((element, index) => 
    <tr key={index}>
        <td>
        <input type='checkbox'
        name='animal' 
        value={element.email}
        onChange={(e) => {onChangeEach(e, element.email); }} checked={checkList.includes(element.email)}/> 
        </td>

      
        {element.inuse ===1 ? (<td data-label="재직">재직</td>):(<td className="j_name">퇴사</td>)}
    <td data-label="이름"><Link to="/org/users/userinfo" state={{member : element}}>{element.name}</Link></td>
    <td data-label="아이디"><Link to="/org/users/userinfo" state={{member : element}}>{element.user_id}</Link></td>
    <td data-label="부서"><Link to="/org/users/userinfo" state={{member : element}}>{element.d_name}</Link></td>
    <td data-label="직위"><Link to="/org/users/userinfo" state={{member : element}}>{element.job_name}</Link></td>
    <td data-label="입사일자"><Link to="/org/users/userinfo" state={{member : element}}>{element.start}</Link></td>
    {element.inuse ===1 ? (<td data-label="상태">활성</td>):(<td className="j_name">중지</td>)}

    </tr>) }
   
    
  </tbody>
</table>
</div>
</div>
       
     </div>
    
    </div>

  );
};

export default AdminUsers;
