import { NavLink, useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import MailNav from "../components/side/MailNav";
import './css/view.css';
import { useStateWithCallbackLazy } from 'use-state-with-callback';


import LineListAdd from './LineListAdd';

import DocList from "../components/side/DocList";
import AutoComplete from "../components/AutoComplete";
import DocDraftsList from "../components/DocDraftsList";
import Leave from "../components/Leave";
import OT from "../components/OT";
import CancelLeave from "../components/CancelLeave";

import Order from "./forms/Order";
import Confirm from "./forms/Confirm";
import Request from "./forms/Request";
import Repair from "./forms/Repair";
import Rent from "./forms/Rent";
import Service from "./forms/Service";
import Endoscope from "./forms/Endoscope";
import Inspect from "./forms/Inspect";
import Acquire from "./forms/Acquire";
import HR from "./forms/HR";
import Purchase from "./forms/Purchase";


import LogiOrder from "./logistics/Order";

import Expenditure from "./common/Expenditure";
import Receipt from "./common/Receipt";


import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";
import { BsPin } from "react-icons/bs";

import { Workbook } from "@fortune-sheet/react";
import "@fortune-sheet/react/dist/index.css"

import { GiHamburgerMenu } from "react-icons/gi";

let luckysheet;
axios.defaults.withCredentials = true; 
const initialValues = {
  subject: "",
  user_no: "",
  user_name: "",
  form_group :"",
  form_no:"",
  form_name:"",
  cc: "",
  desc:"",
  secured: "0",
  recipient:"",
  expire:"1",
  security:"5",
 
};


const DocWrite = () => {

  const luckyCss = {
    margin: '0px',
    padding: '0px',
    width: '100%',
    height: '100%',
    left: '0px',
}

  const [values, setValues] = useStateWithCallbackLazy(initialValues);
  const [forms, setForms] = useState([]);
  
  const [selectForm, setSelectForm] = useState([]);
  const [user, setUser] = useState([]);
  const [form, setForm] = useStateWithCallbackLazy([]);
  const [form1, setForm1]= useState(false);
  const [form2, setForm2]= useState(false);

  const [lineList, setLineList] = useState([]);
  const [lineName, setLineName] = useState("");
  const [lineNo, setLineNo] = useState(0);
  const [name, setName]= useState("");
  const [, updateState] = React.useState();
  const [divv, setDivv] = useState();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const inputFileRef = useRef();
  //const [shData, setShData]= useState();
  const [leave, setLeave] = useState([]);
  const [ot, setOt]= useState([]);
  const [excelData, setExcelData] = useState();
  const [sidebar, setSidebar] = useState(false);
  const [show, setShow] = useState(false);

  const [orders, setOrders] = useState([]);
  const [showPopup, setPopup] = useState(false);
  const [editLine, setEditLine] = useState(false);

  const [cancelLeave, setCancelLeave]= useState([]);

  const [currentApprover, setCurrentApprover] = useState();
  const [writerName, setWriterName] = useState("");
  const [writerJob, setWriterJob] = useState("");
  const [writerDepartment, setWriterDepartment] = useState("");
  const [writerSignLink, setWriterSignLink] = useState([]);
  const [customName, setCustomName] = useState("");
  const [orderNo, setOrderNo]= useState();
  const [userNo, setUserNo]= useState();
  
  const location = useLocation();

  const toggleLine = () => {
    setEditLine(!editLine);
  };

  const togglePopup = () => {
    setPopup(!showPopup);
};

  const toggleShow = () => {
    setShow(!show);
};
  const { form_no } = useParams();
  const navigate = useNavigate();
  function nextChar(c) {
    return String.fromCharCode(c.charCodeAt(0) + 1);
  }

  const fileChangedHandler = (event) => {
    //let file_size = event.target.files[0].size;
  
    var newFiles = [];
    const LargeFs = [];
    var file__size=0;
    var fs = 0;
    for(let i = 0; i < event.target.files.length; i++){
      file__size+=event.target.files[i].size;
      
      //filesize+=event.target.files[i].size;
      if ((fileSize+file__size)>20971520 || file__size > 20971520){
        LargeFs.push(event.target.files[i]);
        
       
      }else{
        
        fs+=event.target.files[i].size;
        newFiles.push(event.target.files[i]);
        
      }
    }
    setFileSize(fileSize+fs);
    var newArray = fileList.concat(newFiles);
    setLargeFiles(LargeFs);
    setFileList(newArray);
  
  };
  const itscell2 = {
    name: "Sheet1",
    config: {
      
     
      
      columnlen: {
        "0": 131,
        "2": 131,
        "3": 131,
        "4": 131,
        "5": 131,
        "6": 131,
        "7": 131,
        "8": 131,
     
      },
      
   
    },
    id: "0",
    zoomRatio: 1,
    order: "0",
    column: 8,
    row: 20,
    status: 1,
    ch_width: 2361,
    rh_height: 936,
    celldata:[],
    luckysheet_select_save: [
      {
        left: 741,
        width: 138,
        top: 796,
        height: 19,
        left_move: 741,
        width_move: 138,
        top_move: 796,
        height_move: 19,
        row: [33, 33],
        column: [6, 6],
        row_focus: 33,
        column_focus: 6
      }
    ],
    /*calcChain: [
      {
        r: 0,
        c: 3,
        id: "0",
        func: [true, 3, "=Formula!A1+Formula!B1"],
        color: "w",
        parent: null,
        chidren: {},
        times: 0
      }
    ],*/
    scrollLeft: 0,
    scrollTop: 0
  };



  useEffect(()=>{
    axios.get('https://gw.thegmmedical.com:5003/api/getuser')
    .then(function (response){
    setUser(response.data[0]);
    var name = response.data[0].name + " " + response.data[0].job_name+" ("+response.data[0].d_name+")";
    setName(name);
 
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    if (location.state !==null){
   
      console.log("hihi", location.state);
      axios.get('https://gw.thegmmedical.com:5003/api/get_current_approver/'+location.state.doc_no)
    .then(function (response){
      let result = response.data[0];
      if (response.data.length>0){
        if (result.user_no == result.userNo){
          setCurrentApprover(result);
        }
      }


    });
   
    axios.get('https://gw.thegmmedical.com:5003/api/docview/'+location.state.doc_no)
    .then(function (response){
      setWriterName(response.data[0].name);
      setWriterJob(response.data[0].job_name);
      setWriterDepartment(response.data[0].d_name);
      setWriterSignLink(response.data[0].sign_link);
      setCustomName(response.data[0].custom_name);
      //setValues(response.data[0]);


      console.log("doc info, ", response.data);
      setForm({type:response.data[0].type, form_no : response.data[0].form_no, form_name : response.data[0].form_name, custom_name : response.data[0].custom_name});

      setValues(response.data[0], () => {




        if (response.data[0].form_data !== null && response.data[0].form_data !== "undefined" && response.data[0].form_data !==""
        && response.data[0].type ==="excel"){
          setForm({type:"excel"});
          var excel_data = JSON.parse(response.data[0].form_data);
          excel_data.showGridLines = 0;
          var rowN = excel_data.row;
          var height= parseInt(rowN) * 15;
          height = height + "px";
          height = height.toString();
          console.log(height, excel_data);
          console.log("excel_data...", excel_data);
          if (response.data[0].form_type==="fortune"){
          setDivv(<div style={{width:"100vh", height:"600px", overFlow:"visible"}}><Workbook data={[excel_data]} showToolbar={false} showFormulaBar={false} showSheetTabs={false} allowEdit={false} rowHeaderWidth={0} columnHeaderHeight={0}/></div>) ;




          }else{
            const loadLuckysheet = async () => {
              try {
                // Load Luckysheet dynamically
                const luckysheetModule = await import('luckysheet');
                excel_data[0].showGridLines="0";
 
 
                luckysheet = luckysheetModule.default;
         
                luckysheet.create({
                  container: "luckysheet",
                  title: 'Luckysheet sphinx Demo', // set the name of the table
                  data: excel_data,
                  showinfobar: false,
                  allowUpdate :false,
                  showstatisticBar:false,
                  sheetFormulaBar:false,
                  columnHeaderHeight:0,
                  rowHeaderWidth:0,
                  showsheetbar:false,
                  showtoolbar:false,
              })
               
         
         
                // Set the loaded state
              } catch (error) {
                console.error('Error loading Luckysheet:', error);
              }
            };
            loadLuckysheet();
          }
   
       


        }else{
          setDivv(<div></div>)
        }
     
      });
     
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
     






    axios.get('https://gw.thegmmedical.com:5003/api/getuser')
    .then(function (res){
      let userNo = res.data[0].user_no;
      setUserNo(res.data[0].user_no);




      axios.get('https://gw.thegmmedical.com:5003/api/getdocline/'+location.state.doc_no)
        .then(function (l){
       
          var result=l.data;
        for (let i=0;i<l.data.length;i++){
          if (l.data[i].status === 4){
            result[l.data.length-1].sign_link = l.data[i].sign_link;
          }
        }
        let lineUsers = result.map(value => value.user_no);


        setLineList(result);


   
       
        })
        .catch(function (error){
        console.log(error);
        })
        .then(function (){
        //always executed
        });
   
   
   
   
   
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });


    if (location.state.form !==undefined){
      alert("hi?");
      setForm1(true);
      values.form_no=1;
      values.form_group = 1;
      let temp = new Object;
      temp.form_no=1;
      axios.get('https://gw.thegmmedical.com:5003/api/getformlist/1').then(function (response){
        setSelectForm(response.data);
        setValues({
          ...values,
          form_no: response.data[0].form_no,
          form_group: "1"
        });
        values.form_no = response.data[0].form_no;
        if (response.data[0].form_no == 1){


          setForm1(true);
          setSelectForm(response.data);
         
          setForm(response.data[0]);
        }else if (response.data[0].form_no == 2){
          setForm2(true);
          setSelectForm(response.data);
         
          setForm(response.data[0]);
        }else{
          setForm1(false);
          values.form_no = response.data[0].form_no;
          setSelectForm(response.data);
        //setLineList([]);
        var line_no = response.data[0].line_no;
        axios.get('https://gw.thegmmedical.com:5003/api/getformline/'+line_no)
        .then(function (res){
          setLineList(res.data);
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
        });
            }
          setForm(temp);
      });
    }


    axios.get('https://gw.thegmmedical.com:5003/api/getform/'+form_no)
    .then(function (response){


      axios.get('https://gw.thegmmedical.com:5003/api/getallforms')
      .then(function (res){
      setForms(res.data);
      var value ;
      for (let i=0;i<res.data.length;i++){
        if (res.data[i].fgroup_no == response.data[0].form_group){
          value = res.data[i].fgroup_no;
         
        }
      }
      axios.get('https://gw.thegmmedical.com:5003/api/getformlist/'+value,).then(function (response){
        setSelectForm(response.data);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      //setLine(response.data[1]);
     
     
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
     
     


     
    })




    .catch(function (error){
      console.log(error);
    })
    .then(function (){
    });






    }else{
    if(form_no != "0"){
      console.log("formno...........", form_no);
      axios.get('https://gw.thegmmedical.com:5003/api/getform/'+form_no)
    .then(function (response){


      axios.get('https://gw.thegmmedical.com:5003/api/getallforms')
      .then(function (res){
      setForms(res.data);
      var value ;
      for (let i=0;i<res.data.length;i++){
        if (res.data[i].fgroup_no == response.data[0].form_group){
          value = res.data[i].fgroup_no;
         
        }
      }
      axios.get('https://gw.thegmmedical.com:5003/api/getformlist/'+value,).then(function (response){
        setSelectForm(response.data);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      //setLine(response.data[1]);
     
     
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
     
      values.form_no = response.data[0].form_no;
      values.form_group = response.data[0].form_group;
     
      setForm(response.data[0]);
      if (response.data[0].form_data !== null && response.data[0].form_data !== "undefined" && response.data[0].form_data !==""){
      /*var excel_data = JSON.parse(response.data[0].form_data);
      setDivv(<div style={{width:"100%"}} className="form_content"><Workbook data={[excel_data]} resized={()=>console.log()} onChange={(data)=>{console.log("changed, ", data); setExcelData(data)}}/></div>) ;
*/


let content = JSON.parse(response.data[0].form_data);


const loadLuckysheet = async () => {
  try {
    // Load Luckysheet dynamically
    const luckysheetModule = await import('luckysheet');
    console.log("luckysheetModule", luckysheetModule);


    luckysheet = luckysheetModule.default;
    console.log("luckysheet,.,..", luckysheet);
    luckysheet.create({
      container: "luckysheet",
      title: 'Luckysheet sphinx Demo', // set the name of the table
      data: content,
      showinfobar: false,
      showsheetbar:false
  })
} catch (error) {
  console.error('Error loading Luckysheet:', error);
}
};
loadLuckysheet();
      }else{
        setDivv(<div></div>)
      }
      let cc = JSON.parse(response.data[0].cc);
      let rec = JSON.parse(response.data[0].recipient);


      console.log("rec,,,,", rec);
      setCcs(cc);
      setRecs(rec);


      axios.get('https://gw.thegmmedical.com:5003/api/getformline/'+response.data[0].line_no)
      .then(function (response){
        setLineList(response.data);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
      });
    })




    .catch(function (error){
      console.log(error);
    })
    .then(function (){
    });


      if (form_no == "1"){
        setForm1(true);
        //setSelectForm(forms[value].list);
        //setForm(forms[location.state.form_no].list[0]);
      }else{
        setForm1(false);
       
        //setSelectForm(forms[value].list);
      }
    }else{
        axios.get('https://gw.thegmmedical.com:5003/api/getallforms')
      .then(function (response){
      setForms(response.data);
     
      //setLine(response.data[1]);
     
     
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });


     
    }
  }
  }, []);




  const handleRegisterButton = () => {
    const formData = new FormData();
    console.log("form_no.....", form1, leave.days);
    if ( form.form_no==="0"){
      return alert("양식을 선택해주세요.");
    }
    
    if (values.subject.length===0){
      return alert("제목을 입력해주세요.");
    }
    if (lineList.length===0){
      return alert("결재라인을 선택해주세요.");
    }

    

    for (let i = 0; i<fileList.length;i++){
      formData.append(`file[${i}]`, fileList[i]);
      formData.append(`filename[${i}]`, fileList[i].name);
    }
    for (let i = 0; i<largeFiles.length;i++){
      formData.append(`lfile[${i}]`, largeFiles[i]);
      formData.append(`lfilename[${i}]`, largeFiles[i].name);
    }
    formData.append(`status`, 1);
    formData.append(`doc_no`, values.doc_no);

    formData.append(`subject`, values.subject);
    formData.append(`user_no`, user.user_no);
    formData.append(`user_name`, name);
    formData.append(`form_group`, values.form_group);
    formData.append(`form_no`, form.form_no);
    formData.append(`form_type`, form.type);

    formData.append(`form_name`, form.form_name);
    formData.append(`line_list`, JSON.stringify(lineList));
    formData.append(`cc`, JSON.stringify(ccs));
    formData.append(`recipient`, JSON.stringify(recs));
    formData.append(`expire`, values.expire);
    formData.append(`line_no`, lineNo);
    formData.append(`secured`, values.secured);
    formData.append(`security`, values.security);
    /*if (excelData!==undefined){
      var rowN;
      var testdata = excelData[0];
    const asdf = testdata.data;
    rowN = testdata.row;
    var celldataa = [];
    for (let i = 0; i<asdf.length;i++){
      for (let j=0;j<asdf[i].length;j++){
        if (asdf[i][j]!==null){
            var trows = {
            r: i,
            c: j,
            v: {
              ct: asdf[i][j].ct,
              v : asdf[i][j].v,
              bg: asdf[i][j].bg,
              m: asdf[i][j].m,
              bl:asdf[i][j].bl,
              it:asdf[i][j].it,
              ff:asdf[i][j].ff,
              fs:asdf[i][j].fs,
              fc:asdf[i][j].fc,
              ht:asdf[i][j].ht,
              vt:asdf[i][j].t,
            }
          };
          if (asdf[i][j].mc!==null){
            trows.v.mc = asdf[i][j].mc;
          }
          if (asdf[i][j].f!==null){
            trows.v.f = asdf[i][j].f;
          }
          celldataa.push(trows);
        }
        
      }
    }

    itscell2.calcChain = testdata.calcChain;
    itscell2.config = testdata.config;
    itscell2.celldata = celldataa;
    itscell2.row = rowN;
    
    const fileData = JSON.stringify(itscell2);
    formData.append(`form_data`, fileData);
    
  }*/
  if (luckysheet!==undefined){
    let sheet = luckysheet.getAllSheets();
    console.log("sheet", sheet);
   
  
  
    formData.append(`form_data`, JSON.stringify(sheet));
  }
 

  if (values.form_no == "1" || form1){
    formData.append(`leave_info`, JSON.stringify(leave.arr));
    if (leave.arr===undefined){
      return alert("내용을 입력해주세요.");
    }
    for (let i=0;i<leave.arr.length;i++){
      if (leave.arr[i].start_date ==="" ||leave.arr[i].end_date ==="" ){
        return alert("날짜를 입력해주세요.");
      }
    }
    /*formData.append(`range`, leave.range);

    var difference = new Date(leave.endDate) - new Date(leave.startDate);
    var days = difference/(24*3600*1000);
    days+=1;

    formData.append(`days`, days);
    formData.append(`startDate`, leave.startDate);
    formData.append(`endDate`, leave.endDate);
    formData.append(`startTime`, leave.startHour+":"+leave.startMin);
    formData.append(`endTime`, leave.endHour+":"+leave.endMin);
    formData.append(`reason`, leave.reason);
    formData.append(`contact`, leave.contact);*/

    
    
    

  }
  if (values.form_no == "2" || form2){
   
   




    formData.append(`ot_info`, JSON.stringify(ot.arr));




  }

  if (form.form_no == "3" ){
    formData.append(`leave_no`, cancelLeave.leave_no);
    formData.append(`cancel_doc_no`, cancelLeave.cancel_doc_no);
    formData.append(`reason`, cancelLeave.reason);
    formData.append(`select_date`, cancelLeave.select_date);
    formData.append(`leave_start`, cancelLeave.leave_start);
    formData.append(`leave_start`, cancelLeave.leave_start);
  
   /* values.leave_end = data[0].end_date,
    values.leave_days = data[0].days,
    values.leave_type = data[0].vtype,
    values.leave_range = data[0].vrange*/

  }
    const fetch = async () => {
      await axios
        .post("https://gw.thegmmedical.com:5003/api/docsend", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          //        
          if (form.type==="custom"){
            axios.post('https://gw.thegmmedical.com:5003/api/forms_insert/'+form.custom_name+'/'+data.insertId, orders)
            .then(function (response){
              navigate('/docview', { state: {doc_no:data.insertId} }); 
              //alert("메뉴 바로가기에 추가되었습니다.");
             
            })
            .catch(function (error){
              console.log(error);
            })
            .then(function (){
              //always executed
            });
          }else{
            navigate('/docview', { state: {doc_no:data.insertId} }); 
          }
          
        });
        /*const data ={
          type : leave.type,
          range : leave.range,
          days: leave.days,
          startDate: leave.startDate,
          endDate : leave.endDate,
          startTime: leave.startHour+":"+leave.startMin,
          endTime : leave.endHour+":"+leave.endMin,
          reason: leave.reason,
          contact: leave.contact
        }

        
            axios.post(
              "https://gw.thegmmedical.com:5003/api/docinsert",
              data
            ).then(( res ) => {

              
            
            });*/
    
    };

    fetch();
   



  };
  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSelectChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    setDivv("");
    const { name, value} = e.target;
  
    setLineName("");
    axios.get('https://gw.thegmmedical.com:5003/api/getformlist/'+value,).then(function (response){
        setSelectForm(response.data);
        setValues({
          ...values,
          form_no: response.data[0].form_no,
          form_group: value
        });
        values.form_no = response.data[0].form_no;
        if (response.data[0].form_no == 1){

          setForm1(true);
          setSelectForm(response.data);
          
          setForm(response.data[0]);

          let cc = JSON.parse(response.data[0].cc);
      console.log("cc....", cc);
      let rec = JSON.parse(response.data[0].recipient);
      for (let i=0;i<cc.length;i++){
        cc[i].preset = true;
      }
      for (let i=0;i<rec.length;i++){
        rec[i].preset = true;
      }
      setCcs(cc);
      setRecs(rec);

        }else if (response.data[0].form_no == 2){
          setForm2(true);
          setSelectForm(response.data);
          
          setForm(response.data[0]);
        }else{
          setForm1(false);
          values.form_no = response.data[0].form_no;
      setSelectForm(response.data);
    //setLineList([]);
    var line_no = response.data[0].line_no;
    axios.get('https://gw.thegmmedical.com:5003/api/getformline/'+line_no)
    .then(function (res){
      
      setLineList(res.data);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
    });

    axios.get('https://gw.thegmmedical.com:5003/api/getform/'+response.data[0].form_no)
    .then(function (res){
      console.log("form......", res.data);
      setForm(res.data[0], () => {

      if (res.data[0].form_data !== null && res.data[0].form_data !== "undefined" && res.data[0].form_data !==""){
        let content = JSON.parse(res.data[0].form_data);
        const loadLuckysheet = async () => {
          try {
            // Load Luckysheet dynamically
            const luckysheetModule = await import('luckysheet');
            console.log("luckysheetModule", );
        
        
            luckysheet = luckysheetModule.default;
        
            luckysheet.create({
              container: "luckysheet",
              title: 'Luckysheet sphinx Demo', // set the name of the table
              data: content,
              showinfobar: false,
              showsheetbar:false
            })
           
        
        
            // Set the loaded state
          } catch (error) {
            console.error('Error loading Luckysheet:', error);
          }
        };
        loadLuckysheet();
      }else{
        setDivv(<div></div>)
      }
    });
      let cc = JSON.parse(res.data[0].cc);
      console.log("cc....", cc);
      let rec = JSON.parse(res.data[0].recipient);
      for (let i=0;i<cc.length;i++){
        cc[i].preset = true;
      }
      for (let i=0;i<rec.length;i++){
        rec[i].preset = true;
      }
      setCcs(cc);
      setRecs(rec);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
    });

        }
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });

    

      

        //setSelectForm(forms[index].list);
    
   

  };
  const handleFormChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    setDivv(<div></div>);
    const { name, value} = e.target;
    setLineList("");
    setLineName("");
    setValues({
      ...values,
      [name]: value,
    });
    if (value === "1"){
      setForm1(true);
      setForm2(false);
    }else if (value ==="2" ){
      setForm2(true);
      setForm1(false);
    }else{
      setForm1(false);
      setForm2(false);
    }


    axios.get('https://gw.thegmmedical.com:5003/api/getform/'+value)
    .then(function (res){
      setForm(res.data[0], () => {

      if (res.data[0].form_data !== null && res.data[0].form_data !== "undefined" && res.data[0].form_data !==""){
        let content = JSON.parse(res.data[0].form_data);
        const loadLuckysheet = async () => {
          try {
            // Load Luckysheet dynamically
            const luckysheetModule = await import('luckysheet');
            console.log("luckysheetModule", );
        
        
            luckysheet = luckysheetModule.default;
        
            luckysheet.create({
              container: "luckysheet",
              title: 'Luckysheet sphinx Demo', // set the name of the table
              data: content,
              showinfobar: false,
              showsheetbar:false
          })
           
        
        
            // Set the loaded state
          } catch (error) {
            console.error('Error loading Luckysheet:', error);
          }
        };
        loadLuckysheet();
      }else{
        setDivv(<div></div>)
      }
    });
        let cc = JSON.parse(res.data[0].cc);
        console.log("cc....", cc);
        let rec = JSON.parse(res.data[0].recipient);
        for (let i=0;i<cc.length;i++){
          cc[i].preset = true;
        }
        for (let i=0;i<rec.length;i++){
          rec[i].preset = true;
        }
        setCcs(cc);
        setRecs(rec);
      axios.get('https://gw.thegmmedical.com:5003/api/getformline/'+res.data[0].line_no)
    .then(function (response){
      console.log("line list....", response.data);
      setLineList(response.data);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
    });

      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
    });

  
  };
  
  const [admin, setAdmin]=useState("");
  const [admins, setAdmins]=useState([]);
  

  const generateId_ad = () => {
    const highestId = Math.max.apply(Math, admins.map(function (element) {
           return element.id;
       }));
       let newId = 1; // default in case the array is empty

       if (highestId > 0) {
           // generate a new ID based off of the highest existing element ID 
           newId = (highestId + 1);
       }
       return newId;
 };

  function createNewToDoItem_ad() {
    if (admin !== '') {
      const tempStr = admin.substring(admin.indexOf("<")+1, admin.indexOf(">"));
      const item = { id: generateId_ad(), text: admin, email : tempStr  };
      console.log("item...", item);
      var valueArr = admins.map(function(item){ return item.text });
      if (valueArr.includes(admin)){
        return false;
      }
      else{
        const tempArray = [...admins, item];
        setAdmins(tempArray);
      }

      
    }
    setAdmin('');
  }

  function addAdmins() {
    console.log(admin);
    createNewToDoItem_ad();
  }
  const deleteItem_ad = id => {
    setAdmins(admins.filter(item => item.id !== id));
  };


  const display_admin = admins.map(item => (
    <div className="admin"><li key={item.id}>
      {item.text}
    </li>
    <span className="del_btn" onClick={() => deleteItem_ad(item.id)}>&times;</span>

    </div>
  ));


  
  const [rec, setRec]=useState("");
  const [recs, setRecs]=useState([]);
  

  const generateId_rec = () => {
    const highestId = Math.max.apply(Math, recs.map(function (element) {
           return element.id;
       }));
       let newId = 1; // default in case the array is empty

       if (highestId > 0) {
           // generate a new ID based off of the highest existing element ID 
           newId = (highestId + 1);
       }
       return newId;
 };

  function createNewToDoItem_rec() {
    if (rec !== '') {
      const tempStr = rec.substring(rec.indexOf("<")+1, rec.indexOf(">"));
      const item = { id: generateId_rec(), text: rec, email : tempStr, check : false  };
      console.log("item...", item);
      var valueArr = recs.map(function(item){ return item.text });
      if (valueArr.includes(rec)){
        return false;
      }
      else{
        const tempArray = [...recs, item];
        setRecs(tempArray);
      }

      
    }
    setRec('');
  }

  function addRecs() {
    console.log(rec);
    createNewToDoItem_rec();
  }
  const deleteItem_rec = id => {
    setRecs(recs.filter(item => item.id !== id));
  };


  const display_rec = recs.map(item => (
    <div className="admin"><li key={item.id}>
      {item.text}
    </li>
    {item.preset !==true && (
    <span className="del_btn" onClick={() => deleteItem_rec(item.id)}>&times;</span>
    )}

    </div>
  ));

  const [cc, setCc]=useState("");
  const [ccs, setCcs]=useState([]);
  

  const generateId_cc = () => {
    const highestId = Math.max.apply(Math, ccs.map(function (element) {
           return element.id;
       }));
       let newId = 1; // default in case the array is empty

       if (highestId > 0) {
           // generate a new ID based off of the highest existing element ID 
           newId = (highestId + 1);
       }
       return newId;
 };

  function createNewToDoItem_cc() {
    if (cc !== '') {
      const tempStr = cc.substring(cc.indexOf("<")+1, cc.indexOf(">"));
      const item = { id: generateId_cc(), text: cc, email : tempStr, check : false  };
      console.log("item...", item);
      var valueArr = ccs.map(function(item){ return item.text });
      if (valueArr.includes(cc)){
        return false;
      }
      else{
        const tempArray = [...ccs, item];
        setCcs(tempArray);
      }

      
    }
    setCc('');
  }

  function addCcs() {
    console.log(rec);
    createNewToDoItem_cc();
  }
  const deleteItem_cc= id => {
    setCcs(ccs.filter(item => item.id !== id));
  };


  const display_cc = ccs.map(item => (
    <div className="admin"><li key={item.id}>
      {item.text}
    </li>
    {item.preset !==true && (
    <span className="del_btn" onClick={() => deleteItem_cc(item.id)}>&times;</span>
    )};
    </div>
  ));

  const itscell = {
    name: "Sheet1",
    config: {
      
     
      
      columnlen: {
        "0": 131,
        "2": 131,
        "3": 131,
        "4": 131,
        "5": 131,
        "6": 131,
        "7": 131,
        "8": 131,
     
      },
      
   
    },
    id: "0",
    zoomRatio: 1,
    order: "0",
    column: 8,
    row: 68,
    status: 1,
    ch_width: 2361,
    rh_height: 936,
    celldata:[],
    luckysheet_select_save: [
      {
        left: 741,
        width: 138,
        top: 796,
        height: 19,
        left_move: 741,
        width_move: 138,
        top_move: 796,
        height_move: 19,
        row: [33, 33],
        column: [6, 6],
        row_focus: 33,
        column_focus: 6
      }
    ],
    /*calcChain: [
      {
        r: 0,
        c: 3,
        id: "0",
        func: [true, 3, "=Formula!A1+Formula!B1"],
        color: "w",
        parent: null,
        chidren: {},
        times: 0
      }
    ],*/
    scrollLeft: 0,
    scrollTop: 0
  };


  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  window.addEventListener('message', (event) => {
    if (event.data["line_no"] !== undefined){
      var line_no = event.data["line_no"];
      var line_name = event.data["line_name"];
      var line_list = event.data["line_list"];
      
      setLineNo(line_no);
      setLineName(line_name);
      setLineList(line_list);
    }

   
  });
////////////////////////////////////////////////
// EXCEL IMPORT
////////////////////////////////////////////////

function delLine(){
  setLineList([]);
  setLineName("");
  setLineNo();
}

const onBtnClick = () => {
  /*Collecting node-element and performing click*/
  inputFileRef.current.click();
};
function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes'

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const removeFile = (i) => {
  setFileList([...fileList.filter((_, index) => index !== i)]);
  setFileSize(fileSize-fileList[i].size);
};

const handleDraftButton = () => {
  const formData = new FormData();
  console.log("form_no.....", form1, leave.days);
  if ( form.form_no==="0"){
    return alert("양식을 선택해주세요.");
  }
  
  if (values.subject.length===0){
    return alert("제목을 입력해주세요.");
  }
 

  

  for (let i = 0; i<fileList.length;i++){
    formData.append(`file[${i}]`, fileList[i]);
    formData.append(`filename[${i}]`, fileList[i].name);
  }
  for (let i = 0; i<largeFiles.length;i++){
    formData.append(`lfile[${i}]`, largeFiles[i]);
    formData.append(`lfilename[${i}]`, largeFiles[i].name);
  }
  formData.append(`status`, 0);
  formData.append(`doc_no`, values.doc_no);

  formData.append(`subject`, values.subject);
  formData.append(`user_no`, user.user_no);
  formData.append(`user_name`, name);
  formData.append(`form_group`, values.form_group);
  formData.append(`form_no`, form.form_no);
  formData.append(`form_type`, form.type);

  formData.append(`form_name`, form.form_name);
  formData.append(`line_list`, JSON.stringify(lineList));
  formData.append(`cc`, JSON.stringify(ccs));
  formData.append(`recipient`, JSON.stringify(recs));
  formData.append(`expire`, values.expire);
  formData.append(`line_no`, lineNo);
  formData.append(`secured`, values.secured);
  formData.append(`security`, values.security);
  /*if (excelData!==undefined){
    var rowN;
    var testdata = excelData[0];
  const asdf = testdata.data;
  rowN = testdata.row;
  var celldataa = [];
  for (let i = 0; i<asdf.length;i++){
    for (let j=0;j<asdf[i].length;j++){
      if (asdf[i][j]!==null){
          var trows = {
          r: i,
          c: j,
          v: {
            ct: asdf[i][j].ct,
            v : asdf[i][j].v,
            bg: asdf[i][j].bg,
            m: asdf[i][j].m,
            bl:asdf[i][j].bl,
            it:asdf[i][j].it,
            ff:asdf[i][j].ff,
            fs:asdf[i][j].fs,
            fc:asdf[i][j].fc,
            ht:asdf[i][j].ht,
            vt:asdf[i][j].t,
          }
        };
        if (asdf[i][j].mc!==null){
          trows.v.mc = asdf[i][j].mc;
        }
        if (asdf[i][j].f!==null){
          trows.v.f = asdf[i][j].f;
        }
        celldataa.push(trows);
      }
      
    }
  }

  itscell2.calcChain = testdata.calcChain;
  itscell2.config = testdata.config;
  itscell2.celldata = celldataa;
  itscell2.row = rowN;
  
  const fileData = JSON.stringify(itscell2);
  formData.append(`form_data`, fileData);
  
}*/
if (luckysheet!==undefined){
  let sheet = luckysheet.getAllSheets();
  console.log("sheet", sheet);
 


  formData.append(`form_data`, JSON.stringify(sheet));
}


if (values.form_no == "1" || form1){
  formData.append(`leave_info`, JSON.stringify(leave.arr));
  if (leave.arr===undefined){
    return alert("내용을 입력해주세요.");
  }
  for (let i=0;i<leave.arr.length;i++){
    if (leave.arr[i].start_date ==="" ||leave.arr[i].end_date ==="" ){
      return alert("날짜를 입력해주세요.");
    }
  }
  /*formData.append(`range`, leave.range);

  var difference = new Date(leave.endDate) - new Date(leave.startDate);
  var days = difference/(24*3600*1000);
  days+=1;

  formData.append(`days`, days);
  formData.append(`startDate`, leave.startDate);
  formData.append(`endDate`, leave.endDate);
  formData.append(`startTime`, leave.startHour+":"+leave.startMin);
  formData.append(`endTime`, leave.endHour+":"+leave.endMin);
  formData.append(`reason`, leave.reason);
  formData.append(`contact`, leave.contact);*/

  
  
  

}
if (values.form_no == "2" || form2){
 
 




  formData.append(`ot_info`, JSON.stringify(ot.arr));




}

if (form.form_no == "3" ){
  formData.append(`leave_no`, cancelLeave.leave_no);
  formData.append(`cancel_doc_no`, cancelLeave.cancel_doc_no);
  formData.append(`reason`, cancelLeave.reason);
  formData.append(`select_date`, cancelLeave.select_date);


}
  const fetch = async () => {
    await axios
      .post("https://gw.thegmmedical.com:5003/api/docsend", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        //        
        if (form.type==="custom"){
          axios.post('https://gw.thegmmedical.com:5003/api/forms_insert/'+form.custom_name+'/'+data.insertId, orders)
          .then(function (response){

            let navigate_doc_no = data.insertId;
            if (values.doc_no!==undefined){
              navigate_doc_no = values.doc_no;
            }
            navigate('/docview', { state: {doc_no:navigate_doc_no} });


            //navigate('/docview', { state: {doc_no:data.insertId} }); 
            
            //alert("메뉴 바로가기에 추가되었습니다.");
           
          })
          .catch(function (error){
            console.log(error);
          })
          .then(function (){
            //always executed
          });
        }else{

          let navigate_doc_no = data.insertId;
          if (values.doc_no!==undefined){
            navigate_doc_no = values.doc_no;
          }
          navigate('/docview', { state: {doc_no:navigate_doc_no} });

          //navigate('/docview', { state: {doc_no:data.insertId} }); 
        }
        
      });
      /*const data ={
        type : leave.type,
        range : leave.range,
        days: leave.days,
        startDate: leave.startDate,
        endDate : leave.endDate,
        startTime: leave.startHour+":"+leave.startMin,
        endTime : leave.endHour+":"+leave.endMin,
        reason: leave.reason,
        contact: leave.contact
      }

      
          axios.post(
            "https://gw.thegmmedical.com:5003/api/docinsert",
            data
          ).then(( res ) => {

            
          
          });*/
  
  };

  fetch();
 



};

const addBookmarkPage = () => {
  var result = window.confirm("메뉴 바로가기에 추가하시겠습니까?");
  
  if(result){
    const data ={
      name: values.form_no,
      num : '',
      link : window.location.pathname,
      bookmark : "전자결재 작성"
    }
    console.log(window.location.pathname);
    console.log(data);
    axios.post('https://gw.thegmmedical.com:5003/api/add_bookmark_page', data)
    .then(function (response){
      console.log(response);
      alert("메뉴 바로가기에 추가되었습니다.");

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
};
  return (
    <div className="wrapper">

<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <DocList/>
        </div>
      )}
<div className="content-wrap">

{editLine ? (
  <LineListAdd data = {lineList} lineName={lineName} lineNo={lineNo} closePopup={() => toggleLine()} onChange={(value)=>{

    console.log("value,,,", value);
    var line_no = value.line_no;
    var line_name = value.line_name;
    var line_list = value.line_list;
    
    setLineNo(line_no);
    setLineName(line_name);
    setLineList(line_list);
    setEditLine(false);
  }}/>
) : null}



{show ? (
    <DocDraftsList 
    onChange={(value) => {
      console.log("draft..", value);

      axios.get('https://gw.thegmmedical.com:5003/api/get_current_approver/'+value.doc_no)
    .then(function (response){
      let result = response.data[0];
      if (response.data.length>0){
        if (result.user_no == result.userNo){
          setCurrentApprover(result);
        }
      }

    });
    
    axios.get('https://gw.thegmmedical.com:5003/api/docview/'+value.doc_no)
    .then(function (response){

      
      setWriterName(response.data[0].name);
      setWriterJob(response.data[0].job_name);
      setWriterDepartment(response.data[0].d_name);
      setWriterSignLink(response.data[0].sign_link);
      setCustomName(response.data[0].custom_name);
      setValues(response.data[0]);
    axios.get('https://gw.thegmmedical.com:5003/api/getuser')
    .then(function (res){
      let userNo = res.data[0].user_no;
      setUserNo(res.data[0].user_no);


      axios.get('https://gw.thegmmedical.com:5003/api/getdocline/'+value.doc_no)
        .then(function (l){
        
          var result=l.data;
        for (let i=0;i<l.data.length;i++){
          if (l.data[i].status === 4){
            result[l.data.length-1].sign_link = l.data[i].sign_link;
          }
        }
        let lineUsers = result.map(value => value.user_no);

        setLineList(result);

    
        
        })
        .catch(function (error){
        console.log(error);
        })
        .then(function (){
        //always executed
        });
    
    
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
      
    
    
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });



    if(value.form_no != "0"){
      
      axios.get('https://gw.thegmmedical.com:5003/api/getform/'+value.form_no )
    .then(function (response){

      axios.get('https://gw.thegmmedical.com:5003/api/getallforms')
      .then(function (res){
      setForms(res.data);
      var value ;
      for (let i=0;i<res.data.length;i++){
        if (res.data[i].fgroup_no == response.data[0].form_group){
          value = res.data[i].fgroup_no;
          
        }
      }
      axios.get('https://gw.thegmmedical.com:5003/api/getformlist/'+value,).then(function (response){
        setSelectForm(response.data);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      //setLine(response.data[1]);
      
      
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
      
      values.form_no = response.data[0].form_no;
      values.form_group = response.data[0].form_group;
      
      setForm(response.data[0], () => {

      if (response.data[0].form_data !== null && response.data[0].form_data !== "undefined" && response.data[0].form_data !==""){
        let content = JSON.parse(response.data[0].form_data);
        const loadLuckysheet = async () => {
          try {
            // Load Luckysheet dynamically
            const luckysheetModule = await import('luckysheet');
            console.log("luckysheetModule", );
        
        
            luckysheet = luckysheetModule.default;
        
            luckysheet.create({
              container: "luckysheet",
              title: 'Luckysheet sphinx Demo', // set the name of the table
              data: content,
              showinfobar: false,
              showsheetbar:false
          })
           
        
        
            // Set the loaded state
          } catch (error) {
            console.error('Error loading Luckysheet:', error);
          }
        };
        loadLuckysheet();
      }else{
        setDivv(<div></div>)
      }
    });
      let cc = JSON.parse(response.data[0].cc);
      let rec = JSON.parse(response.data[0].recipient);
      for (let i=0;i<cc.length;i++){
        cc[i].preset = true;
      }
      for (let i=0;i<rec.length;i++){
        rec[i].preset = true;
      }
      setCcs(cc);
      setRecs(rec);

      axios.get('https://gw.thegmmedical.com:5003/api/getformline/'+response.data[0].line_no)
      .then(function (response){
        setLineList(response.data);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
      });
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
    });

      if (form_no == "1"){
        setForm1(true);
        //setSelectForm(forms[value].list);
        //setForm(forms[location.state.form_no].list[0]);
      }else{
        setForm1(false);
        
        //setSelectForm(forms[value].list);
      }
    }else{
        axios.get('https://gw.thegmmedical.com:5003/api/getallforms')
      .then(function (response){
      setForms(response.data);
      
      //setLine(response.data[1]);
      
      
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });

      axios.get('https://gw.thegmmedical.com:5003/api/getuser')
      .then(function (response){
      setUser(response.data[0]);
      var name = response.data[0].name + " " + response.data[0].job_name+" ("+response.data[0].d_name+")";
      setName(name);
    
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
    }
    
    
    }} closePopup={() => toggleShow()}/>
    ) : null}
      
    <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>
    <div className="side-menu">
      <DocList/>
    </div>
    

    <div className="docwrite main_content">
  
    <div className="btns" style={{flexDirection:"row", justifyContent:"start", alignItems:"center", margin:"0"}}>
      <div className="btn"  style={{width:"15px", height:"15px", padding:"4px 3px", display:"flex", alignItems:"center", marginRight:"7px"}} onClick={()=>addBookmarkPage()}><BsPin/></div><h3>전자결재 작성</h3>
      </div>

            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
      <h4>문서 기안하기 </h4>

      

              <div className="right">
                <button className="btn" onClick={handleDraftButton}>임시저장</button>
                <button onClick={() => {setShow(!show);}}>임시저장글</button> 

            </div>
            
          </div>
          <div style={{background:"#fff", padding:"0"}}>
          {(lineList.length >0) && (
            <div style={{display:"flex"}} className="line">

            <table>
            <tr className="name"><td rowSpan={5} className="type">작성자</td><td>{user.job_name}</td></tr>
            <tr className="name"><td>{user.d_name}</td></tr>
            <tr className="name"><td>{user.name}</td></tr>
            <tr><td>
            <div style={{height:"40px", display:"flex", alignItems:"center"}}> <img src={user.sign_link} alt="preview" style={{width:"40px"}}/> </div>
            </td></tr>
            <tr></tr>
            </table>


                {lineList.map((element, index)=> (
                  <table>
                    <tr className="name"><td rowSpan={3} className="type">{element.type}</td><td>{element.job_name}</td></tr>
                    <tr className="name"><td>{element.d_name}</td></tr>
                    <tr className=""><td>{element.name}</td></tr>
                    <tr></tr>
                  </table>
                ))}
            </div>
          )}
          <div style={{display:"flex", width:"100%", justifyContent:"space-between", flexDirection:"column"}}>
          

         
          <table className="form_write" style={{margin:"10px 0", width:"100%"}}>

      {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}

      <tr>
<td class="table_title2" nowrap="">
  양식분류
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <select name="form_group" className="formInput" value={values.form_group ||''}
                                  onChange={handleSelectChange}>    
                                  <option value="">분류 선택</option>
      {forms.map((element, index)=>(
      <option value={element.fgroup_no} className={element.fgroup_no}>{element.fgroup_name}</option>
    ))}
  </select>
  {selectForm !==undefined &&
  selectForm.length>0 && (
  <select name="form_no" className="formInput" value={values.form_no ||''}
                                  onChange={handleFormChange}> 

  
      {selectForm.map((element, index)=>(
      <option value={element.form_no} className={element.form_no}>{element.form_name}</option>
    ))}
    
    
  </select> 
  )}  
  </div>
  </td>


  <Tooltip
        id="date_guide"
        place="bottom"
        >
          <div style={{display:"flex", flexDirection:"column", textAlign:"left"}}>
            <span>양식의 상태를 설정합니다.</span>
          </div>
        </Tooltip>


</tr>


      <tr>
<td class="table_title2" nowrap="">
  제목
</td>
  <td class="table_list2_left">
  <div className="input-group">
  <input type="text" name="subject" className="formInput" placeholder="제목을 입력하세요." value={values.subject}
          onChange={handleInputChange}></input>
  </div>
  </td>

  <td class="table_title2 pc" nowrap="">
  작성자
</td>
  <td class="table_list2_left pc">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <span>{name}</span>
  </div>
  </td>

</tr>      
      
  <tr className="mobile">
  <td class="table_title2" nowrap="">
  작성자
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <span>{user.name}&nbsp;{user.job_name}&nbsp;({user.d_name})</span>
  </div>
  </td>
  </tr>
   






<tr>
<td class="table_title2" nowrap="">
  결재라인
</td>
  <td class="table_list2_left">
  <div className="input-group">
  
  {lineName !=="" && (
    <div>
      <span>{lineName} </span> &nbsp;&nbsp; <button className="trash" onClick={()=>delLine()}>삭제</button>
    </div>
  )}
 {/*
    <button onClick={() => popupwindow("/lineadd", "결재라인", 1600, 600)} alt=''>결재라인 선택</button>
  */}


<button onClick={() => toggleLine()} alt=''>결재라인 선택</button>
  </div>
  </td>



</tr>

{/*
<tr>
<td class="table_title2" nowrap="">
  보안결재
</td>
<td class="table_list2_left">
  <div className="input-group radio" style={{display:"flex"}}>
      
        <input type="radio" value="1" name="secured" checked={values.secured === "1"} onChange={handleInputChange}/><label><span>사용</span>
      </label>
      <label>
        <input type="radio" value="0" name="secured" checked={values.secured === "0"} onChange={handleInputChange}/><span>사용안함</span>
      </label>
  </div>
  </td>


</tr>

  */}
<tr>
<Tooltip
        id="cc"
        place="bottom"
        >
          <div style={{display:"flex", flexDirection:"column", textAlign:"left"}}>
            <span>문서참조자는 결재문서와 결재상태를 확인할 수 있습니다.</span>
          </div>
        </Tooltip>

<td class="table_title2" nowrap="">
  문서참조자<BiInfoCircle data-tooltip-id="cc" style={{marginLeft:"5px"}}/>
</td>
<td class="table_list2_left" >
        <div className="input-group">
        <div style={{display:"flex"}}>
               <AutoComplete onChange={(value) => {setCc(value); console.log("hihi", value)}}/>
                <button onClick={addCcs} style={{marginLeft:"10px"}}>추가</button>
            </div>
      </div>
      {ccs.length >0 &&(

      <div class="admin_list" style={{flexGrow:"0"}}>
            <ul>
                      {display_cc}
                        </ul>
                    </div>
      )}
		</td>
</tr>

<tr>

<Tooltip
        id="rep"
        place="bottom"
        >
          <div style={{display:"flex", flexDirection:"column", textAlign:"left", zIndex:"9999"}}>
            <span>문서수신자는 결재가 완료된 문서를 확인할 수 있습니다.</span>
          </div>
        </Tooltip>

<td class="table_title2" nowrap="">
  문서수신자<BiInfoCircle data-tooltip-id="rep" style={{marginLeft:"5px"}}/>
</td>
<td class="table_list2_left">
        <div className="input-group">
        <div style={{display:"flex"}}>
               <AutoComplete onChange={(value) => {setRec(value); console.log("hihi", value)}}/>
                <button onClick={addRecs} style={{marginLeft:"10px"}}>추가</button>
            </div>
      </div>
      {recs.length >0 &&(

      <div class="admin_list">
            <ul>
                      {display_rec}
                        </ul>
                    </div>
      )}
		</td>
</tr>

{/*
<tr>

<td class="table_title2" nowrap="">
  보관기간
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <select name="expire" className="formInput" value={values.expire || '1'}
                                  onChange={handleInputChange}>
  <option value="1">1년</option>
  <option value="3">3년</option>
  <option value="5">5년</option>
  </select> 
  </div>
  </td>
  <td class="table_title2 pc" nowrap="">
  보안등급
</td>
  <td class="table_list2_left pc">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <select name="security" className="formInput" value={values.security || '1'}
                                  onChange={handleInputChange}>
  <option value="5">5등급</option>
  <option value="4">4등급</option>
  <option value="3">3등급</option>
  <option value="2">2등급</option>
  <option value="1">1등급</option>

</select> 
  </div>
  </td>

</tr>

<tr className="mobile">
<td class="table_title2" nowrap="">
  보안등급
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <select name="security" className="formInput" value={values.security || '1'}
                                  onChange={handleInputChange}>
  <option value="5">5등급</option>
  <option value="4">4등급</option>
  <option value="3">3등급</option>
  <option value="2">2등급</option>
  <option value="1">1등급</option>

</select> 
  </div>
  </td>
</tr>
*/}
  <tr>
  <td class="table_title2" nowrap="">
    첨부파일
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <input type="file" id="files" name="files" style={{display:"none"}} ref={inputFileRef} onChange={fileChangedHandler} multiple/>
    <button style={{cursor:'pointer'}} className="large_file_btn" onClick={()=>onBtnClick()}>
      파일 첨부
    </button>
    
    </div>
 
    </td>
  </tr>
  <tr style={{height:"auto"}}>
  <td class="table_title2" nowrap="">
    
  </td>
    <td class="upload_file_list">
  {fileList.map((file,index) => 
    <div className="file_item">
      <p>{file.name} {formatBytes(file.size)}</p>
      <button type="button" className="remove" onClick={() => {removeFile(index);}}>x</button>
    </div>
    
  )} 

{largeFiles.map((file,index) => 
    <div>
      <p>{file.name} {formatBytes(file.size)} <span>다운로드 가능 기간</span></p>
      
      <button type="button" onClick={() => {removeFile(index);}}>Remove</button>
    </div>
    
  )} 
    </td>
  </tr>



    </table>

    <div>
    {form.form_no ==1 &&(
  <Leave onChange={(value)=>{console.log("leavelll..", value);setLeave(value);}} doc_no={values.doc_no}/>
)}
{form.form_no ==2 &&(
  <OT onChange={(value)=>{setOt(value);console.log("OT...",value)}} doc_no={values.doc_no}/>
)}  

{form.form_no ==3 &&(
  <CancelLeave onChange={(value)=>{setCancelLeave(value);console.log("...",value)}} doc_no={values.doc_no}/>
)}  

    </div>
    {(form.type ==="custom" &&
<div className="custom_form custom_form_view" style={{height:"600px", overflow:"scroll", position:"relative"}}>

  
{(form.type ==="custom" && form.custom_name==="order") &&(
  <Order onChange={(value) => {console.log("order.............", value);setOrders(value);}} line={lineList} doc_no={values.doc_no} />
)}

{(form.type ==="custom" && form.custom_name==="confirm") &&(
  <Confirm onChange={(value) => {console.log("confirm.............", value);setOrders(value);}} line={lineList} doc_no={values.doc_no}/>
)}

{(form.type ==="custom" && form.custom_name==="request") &&(
  <Request onChange={(value) => {console.log("request.............", value);setOrders(value);}} line={lineList} doc_no={values.doc_no}/>
)}

{(form.type ==="custom" && form.custom_name==="repair") &&(
  <Repair onChange={(value) => {console.log("repair.............", value);setOrders(value);}} line={lineList} doc_no={values.doc_no}/>
)}

{(form.type ==="custom" && form.custom_name==="rent") &&(
  <Rent onChange={(value) => {console.log("rent.............", value);setOrders(value);}} line={lineList} doc_no={values.doc_no}/>
)}

{(form.type ==="custom" && form.custom_name==="service")&&(
  <Service onChange={(value) => {console.log("service.............", value);setOrders(value);}} line={lineList} doc_no={values.doc_no}/>
)}

{(form.type ==="custom" && form.custom_name==="endoscope") &&(
  <Endoscope onChange={(value) => {console.log("service.............", value);setOrders(value);}} line={lineList} doc_no={values.doc_no}/>
)}

{(form.type ==="custom" && form.custom_name==="inspect") &&(
  <Inspect onChange={(value) => {console.log("inspect.............", value);setOrders(value);}} line={lineList} doc_no={values.doc_no}/>
)}

{(form.type ==="custom" && form.custom_name==="acquire") &&(
  <Acquire onChange={(value) => {console.log("acquire.............", value);setOrders(value);}} line={lineList} doc_no={values.doc_no}/>
)}
{(form.type ==="custom" && form.custom_name==="hr") &&(
  <HR onChange={(value) => {console.log("acquire.............", value);setOrders(value);}} line={lineList} doc_no={values.doc_no}/>
)}

{(form.type ==="custom" && form.custom_name==="purchase") &&(
  <Purchase onChange={(value) => {console.log("acquire.............", value);setOrders(value);}} line={lineList} doc_no={values.doc_no}/>
)}

{(form.type ==="custom" && form.custom_name==="logi_order") &&(
  <LogiOrder onChange={(value) => {console.log("acquire.............", value);setOrders(value);}} line={lineList} doc_no={values.doc_no}/>
)}

{(form.type ==="custom" && form.custom_name==="expenditure") &&(
  <Expenditure onChange={(value) => {console.log("acquire.............", value);setOrders(value);}} line={lineList} doc_no={values.doc_no}/>
)}

{(form.type ==="custom" && form.custom_name==="receipt") &&(
  <Receipt onChange={(value) => {console.log("acquire.............", value);setOrders(value);}} line={lineList} doc_no={values.doc_no}/>
)}

</div>
)}










{(form.type ==="excel" || values.type==="excel") &&(<div className="custom_form custom_form_view" style={{height:"600px", overflow:"hidden", position:"relative"}}>

<div
     id="luckysheet"
     style={luckyCss}
   />

</div>)} 
  </div>

   

    </div>


    

    <div className="button_wrap" style={{margin:"20px 0"}}>
    <button onClick={handleRegisterButton}>등록</button>
    
    {/*</form>*/}
    </div>
    </div>
  </div>
  </div>
  </div>

  );
};

export default DocWrite;
