import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import axios from 'axios';

import {pdfjs} from "react-pdf";

import MailList from './pages/MailList';
import MailDone from './pages/MailDone';

import Unseen from './pages/Unseen';
import View from './pages/View';
import Write from './pages/Write';
import WriteToMe from './pages/WriteToMe';
import Login from './pages/Login';
import Reply from './pages/Reply';
import ReplyAll from './pages/ReplyAll';
import Forward from './pages/Forward';
import SortSetting from './pages/settings/SortSetting';
import Home from './pages/Home';
import BigFileUpload from './pages/BigFileUpload';
import Search from './pages/Search';
import Contacts from './pages/Contacts';
import MailSettings from './pages/MailSettings';
import MailListRead from './pages/MailListRead';


import Board from './pages/Board';
import BoardExcel from './pages/BoardExcel';

import BoardView from './pages/BoardView';
import BoardUpdate from './pages/BoardUpdate';
import BoardReply from './pages/BoardReply';



import DeliverySchedule from './pages/DeliverySchedule';
import DeliveryScheduleWrite from './pages/DeliveryScheduleWrite';
import DeliveryScheduleView from './pages/DeliveryScheduleView';
import DeliveryScheduleUpdate from './pages/DeliveryScheduleUpdate';

import Data from './pages/Data';
import DataView from './pages/DataView';
import DataUpdate from './pages/DataUpdate';
import DataReply from './pages/DataReply';
import DataWrite from './pages/DataWrite';

import Org from './pages/Org';
import People from './pages/People';
import SelectMember from './pages/SelectMember';
import HRCard from './pages/HRCard';

import BoardAS from './pages/BoardAS';
import BoardASUpdate from './pages/BoardASUpdate';
import BoardASWrite from './pages/BoardASWrite';

import BoardStats from './pages/BoardStats';


import BoardDelWrite from './pages/BoardDelWrite';
import BoardDel from './pages/BoardDel';
import BoardDelUpdate from './pages/BoardDelUpdate';

import BoardRetentionWrite from './pages/BoardRetentionWrite';
import BoardRetention from './pages/BoardRetention';
import BoardRetentionUpdate from './pages/BoardRetentionUpdate';

import BoardOlympusWrite from './pages/BoardOlympusWrite';
import BoardOlympus from './pages/BoardOlympus';
import BoardOlympusUpdate from './pages/BoardOlympusUpdate';


import BoardInspectWrite from './pages/BoardInspectWrite';
import BoardInspect from './pages/BoardInspect';
import BoardInspectView from './pages/BoardInspectView';
import BoardInspectUpdate from './pages/BoardInspectUpdate';

import BoardHospWrite from './pages/BoardHospWrite';
import BoardHosp from './pages/BoardHosp';
import BoardHospUpdate from './pages/BoardHospUpdate';

import BoardModelWrite from './pages/BoardModelWrite';
import BoardModel from './pages/BoardModel';
import BoardModelUpdate from './pages/BoardModelUpdate';

import BoardDefectWrite from './pages/BoardDefectWrite';
import BoardDefect from './pages/BoardDefect';
import BoardDefectUpdate from './pages/BoardDefectUpdate';

import Password from './pages/Password';
import BoardWrite from './pages/BoardWrite';
import Notice from './pages/Notice';
import Layout from './Layout';

import AdminLayout from './AdminLayout';
import Admin from './pages/Admin';
import AdminUsers from './pages/AdminUsers';
import AdminUserInfo from './pages/AdminUserInfo';
import AdminDepartments from './pages/AdminDepartments';
import AdminJobs from './pages/AdminJobs';
import AdminBoard from './pages/AdminBoard';
import AdminTeamBoard from './pages/AdminTeamBoard';
import AdminData from './pages/AdminData';

import AdminLevel from './pages/AdminLevel';
import AdminAuthority from './pages/AdminAuthority';
import AdminPassword from './pages/AdminPassword';
import AdminLeave from './pages/AdminLeave';
import AdminMailFooter from './pages/AdminMailFooter';
import AdminMailCapa from './pages/AdminMailCapa';
import AdminHR from './pages/AdminHR';
import AdminHoliday from './pages/AdminHoliday';
import AdminHolidayWrite from './pages/AdminHolidayWrite';
import AdminReport from './pages/AdminReport';
import AdminLeaveType from './pages/AdminLeaveType';

import AdminLeaveTypeAdd from './pages/AdminLeaveTypeAdd';
import AdminMailingList from './pages/AdminMailingList';

import SettingUser from './pages/SettingUser';
import SettingPW from './pages/SettingPW';
import SettingBookmark from './pages/SettingBookmark';
import SettingHR from './pages/SettingHR';
import SettingESign from './pages/SettingESign';


import Excel from './pages/Excel';
import DocMain from './pages/DocMain';
import DocWrite from './pages/DocWrite';
import DocReWrite from './pages/DocReWrite';
import Docs from './pages/Docs';
import DocView from './pages/DocView';
import DocSign from './pages/DocSign';
import DocsReceived from './pages/DocsReceived';
import DocSettings from './pages/DocSettings';
import DocsAdmin from './pages/DocsAdmin';
import DocUpdate from './pages/DocUpdate';




import Inspect from './pages/Excel';


import Form from './pages/Form';
import FormAdd from './pages/FormAdd';
import FormView from './pages/FormView';
import FormEdit from './pages/FormEdit';


import LineList from './pages/LineList';
import LineListComponent from './pages/LineListComponent';
import LineListAdd from './pages/LineListAdd';
import LastLines from './pages/LastLines';

import OfficeViewer from './pages/OfficeViewer';

import Schedule from './pages/Schedule';
import ScheduleWrite from './pages/ScheduleWrite';
import ScheduleView from './pages/ScheduleView';
import ScheduleUpdate from './pages/ScheduleUpdate';

import CustomerWrite from './pages/CustomerWrite';
import Customer from './pages/Customer';
import CustomerView from './pages/CustomerView';
import CustomerUpdate from './pages/CustomerUpdate';

import Contracts from './pages/Contracts';
import ContractWrite from './pages/ContractWrite';
import ContractView from './pages/ContractView';

import CalendarAdd from './pages/CalendarAdd';
import CalendarUpdate from './pages/CalendarUpdate';
import CalendarSetting from './pages/CalendarSetting';

import WorkMain from './pages/WorkMain';
import WorkWrite from './pages/WorkWrite';
import WorkReport from './pages/WorkReport';
import WorkList from './pages/WorkList';
import WorkReportDepartment from './pages/WorkReportDepartment'
import WorkView from './pages/WorkView';
import WorkUpdate from './pages/WorkUpdate';
import WorkGroupAdd from './pages/WorkGroupAdd';
import WorkGroupUpdate from './pages/WorkGroupUpdate';
import WorkReportView from './pages/WorkReportView';


import AddressBook from './pages/AddressBook';
import AddressGroupAdd from './pages/AddressGroupAdd';
import AddressList from './pages/AddressList';
import AddressGroupUpdate from './pages/AddressGroupUpdate';
import AddressAdd from './pages/AddressAdd';

import AddressView from './pages/AddressView';
import AddressUpdate from './pages/AddressUpdate';
import AddressQuick from './pages/AddressQuick';

import AllSearch from './pages/AllSearch';

import AddCheckLine from './pages/AddCheckLine';
import Test from './pages/Test';

import './App.css';

import firebase from 'firebase'; //firebase모듈을 import해줘야 합니다.



pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


const config = {


  apiKey: "AIzaSyA4deuG-QRLdxS5iYNAn6bV-Tp4KjepYX0",
  authDomain: "gm-medical-58c86.firebaseapp.com",
  projectId: "gm-medical-58c86",
  storageBucket: "gm-medical-58c86.appspot.com",
  messagingSenderId: "880056248369",
  appId: "1:880056248369:web:aa6bdb95acc074cbd90d10",
  measurementId: "G-ZYGWLVRYFT"

};


let messaging = null; 
firebase.initializeApp(config);


if (firebase.messaging.isSupported()){ 
  messaging = firebase.messaging(); 

  messaging.onMessage(function(payload){

    console.log("foreground, ", payload);
    const notificationTitle = payload.notification.title; 
    const notificationOptions = { 
      body: payload.notification.body, 
      icon: payload.notification.icon,
      //actions:[{action: payload.notification.click_action, title:"그룹웨어 알림"}] 
    }; 
    if (!("Notification" in window)) { 
      alert("This browser does not support system notifications."); 
      //console.log("This browser does not support system notifications."); 
    } else if (Notification.permission === "granted") { 
      // If it's okay let's create a notification 
      var notification = new Notification(notificationTitle,notificationOptions); 
      //alert("foreground");
      notification.onclick = function(event) {
        console.log("foreground 에서 클릭됨"); 
        event.preventDefault(); 
        window.open(payload.notification.click_action , '_blank'); 
        notification.close(); 
      } 
    }

  })

}







/*var fcmToken="";
messaging.requestPermission()
.then(function() {
  console.log('허가!');
  return messaging.getToken(); //토큰을 받는 함수를 추가!
})
.then(function(token) {
  fcmToken = token;
  console.log("fcm token", token); //토큰을 출력!

  const data = {
    token: fcmToken
  }

 


})
.catch(function(err) {
  console.log('fcm에러 : ', err);
})*/





const App = () => {
  
  const [check, setCheck]=useState();
  const [loggedIn, setLoggedIn]=useState();

  function getOS() {
    const userAgent = window.navigator.userAgent,
        platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
        macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;
  
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (/Linux/.test(platform)) {
      os = 'Linux';
    }
  
    return os;
  }

 async function permiss(){
    let permission = await Notification.requestPermission();
    if (permission === "granted") {

    }else{
      
    }

    const msg1 = {
      msg: permission
    }
    axios.post('http://gw.thegmmedical.com:5003/api/updateMsg1', msg1).then(function (response){
      console.log("updated");
    })
    .catch(function (error){
      console.log(error);
    })
      .then(function (){
    });

  }
  useEffect(()=>{
    if (document.getElementsByTagName("main")[0] !==undefined){
      document.getElementsByTagName("main")[0].style.overflow="auto";
    }

    permiss();
    
    if (!('PushManager' in window)) {
      //console.warn('Push messaging isn\'t supported.');

      const msg2 = {
        msg: 'Push messaging isn\'t supported.'
      }
      axios.post('http://gw.thegmmedical.com:5003/api/updateMsg2', msg2).then(function (response){
        console.log("updated");
      })
      .catch(function (error){
        console.log(error);
      })
        .then(function (){
      });
    }
    if (window.innerWidth <= 700){


      let os = getOS();
      let platform = window.navigator?.userAgent;
      const osData = {
        os: os,
        os_json : platform
      }
      axios.post('http://gw.thegmmedical.com:5003/api/updateOs', osData).then(function (response){
        console.log("updated");
      })
      .catch(function (error){
        console.log(error);
      })
        .then(function (){
      });
  
    }
    async function fetchAndSetUser() { 
    //let fcmToken = await firebase.messaging().getToken();
    
    let messaging = firebase.messaging(); 
    let fcmToken = await messaging.getToken({
      vapidKey: "BHBOyeWYKkwGfvXolZnj3C7VMMGABSRDZKuQnMovXtad4Un4MRF6jFMY0LWaW2wbwQQLtEh9zVVjt_bgokjYhwQ",
    });
    
    const data = {
      token: fcmToken,
      page : "app"
    }
    if (window.innerWidth <= 700){
      axios.post('http://gw.thegmmedical.com:5003/api/updateToken', data).then(function (response){
      console.log("updated");
      })
      .catch(function (error){
        console.log(error);
      })
        .then(function (){
      });

  


    }
    }
    if (firebase.messaging.isSupported()){ 

      fetchAndSetUser();
    }
  }, [useParams.id]);
  
  if (loggedIn === false){
    console.log(" 어..");
    return <Login></Login>;
  }else if (loggedIn === undefined){
  if (!check){
    axios.get('http://gw.thegmmedical.com:5003/api/loginCheck').then(function (response){
      if (response.data.msg === "logged in"){
        console.log('logged in checked');
        setCheck(true);
        setLoggedIn(true);
      }else{
        console.log("not logged in");
        setLoggedIn(false);
        return <Login/>;
      }
    })
    .catch(function (error){
      console.log(error);
    })
      .then(function (){
    });

    
  }
  }else{
  
  return (
    
    <Router>
    <Routes>
    <Route element={<AdminLayout />}>
    <Route path="/admin" element={<Admin/>} />
    <Route path="/org/users" element={<AdminUsers/>} />
    <Route path="/org/users/userinfo" element={<AdminUserInfo/>} />
    <Route path="/org/departments" element={<AdminDepartments/>} />
    <Route path="/menu/board" element={<AdminBoard/>} />
    <Route path="/menu/teamboard" element={<AdminTeamBoard/>} />
    <Route path="/menu/data" element={<AdminData/>} />

    <Route path="/security/level" element={<AdminLevel/>} />
    <Route path="/overview/add_admin" element={<AdminAuthority/>}/>
    <Route path="/security/pw" element={<AdminPassword/>}/>
    <Route path="/org/leave" element={<AdminLeave/>}/>
    <Route path="/mail/footer" element={<AdminMailFooter/>}/>
    <Route path="/mail/capa" element={<AdminMailCapa/>}/>
    <Route path="/mail/mailing" element={<AdminMailingList/>}/>

    <Route path="/org/hr" element={<AdminHR/>}/>

    <Route path="/holiday" element={<AdminHoliday/>}/>
    <Route path="/holiday_w" element={<AdminHolidayWrite/>}/>

    <Route path="/leave" element={<AdminLeaveType/>}/>
    <Route path="/leave_w" element={<AdminLeaveTypeAdd/>}/>

    
    <Route path="/org/report" element={<AdminReport/>}/>
    
    <Route path="/org/jobs" element={<AdminJobs/>} />
    </Route>
    <Route element={<Layout />}>
      <Route path="/integrated_search" element={<AllSearch/>} />

      <Route path="/" element={<Home/>} />
      
      <Route path="/list/:name" element={<MailList/>} />
      <Route path="/unseen" element={<Unseen/>} />
      <Route path="/usersetting" element={<SettingUser/>} />
      <Route path="/change_pw" element={<SettingPW/>} />
      <Route path="/bookmark" element={<SettingBookmark/>} />
      <Route path="/hr" element={<SettingHR/>} />
      <Route path="/esign" element={<SettingESign/>} />

      
      <Route path="/:folder/view/:no" element={<View/>} />
      <Route path="/write" element={<Write/>} />
      <Route path="/mail/done" element={<MailDone/>} />


      <Route path="/write_to_me" element={<WriteToMe/>} />
      <Route path="/setting" element={<SortSetting/>} />
      <Route path="/reply" element={<Reply/>} />
      <Route path="/replyall" element={<ReplyAll/>} />
      <Route path="/forward" element={<Forward/>} />
      <Route path="/mailread" element={<MailListRead/>} />

      <Route path="/search" element={<Search/>} />
      
      <Route path="/setting/mail/:menu" element={<MailSettings/>} />
      <Route path="/mail_password" element={<Password/>} />


      <Route path="/board" element={<Board/>} />
      <Route path="/board/:no" element={<BoardExcel/>} />

      <Route path="/contracts" element={<Contracts/>} />
<Route path="/contract_write" element={<ContractWrite/>}/>
<Route path="/contract/:c_no" element={<ContractView/>}/>

      
      <Route path="/dsr" element={<DeliverySchedule/>} />
      <Route path="/dsr_write" element={<DeliveryScheduleWrite/>} />
      <Route path="/dsr_view" element={<DeliveryScheduleView/>} />
      <Route path="/dsr_update" element={<DeliveryScheduleUpdate/>} />


      
      <Route path="/as_status" element={<BoardAS/>} />
      <Route path="/as_write" element={<BoardASWrite/>} />
      <Route path="/as_update" element={<BoardASUpdate/>} />

      <Route path="/del_update" element={<BoardDelUpdate/>} />
      <Route path="/del_write" element={<BoardDelWrite/>} />
      <Route path="/delivery" element={<BoardDel/>} />

      <Route path="/ret_write" element={<BoardRetentionWrite/>} />
      <Route path="/retention" element={<BoardRetention/>} />
      <Route path="/ret_update" element={<BoardRetentionUpdate/>} />

      <Route path="/olym_write" element={<BoardOlympusWrite/>} />
      <Route path="/olympus" element={<BoardOlympus/>} />
      <Route path="/olym_update" element={<BoardOlympusUpdate/>} />


      <Route path="/inspect_write" element={<BoardInspectWrite/>} />
      <Route path="/inspect" element={<BoardInspect/>} />
      <Route path="/inspect_view" element={<BoardInspectView/>} />
      <Route path="/inspect_update" element={<BoardInspectUpdate/>} />

      <Route path="/hosp_w" element={<BoardHospWrite/>} />
      <Route path="/hosp" element={<BoardHosp/>} />
      <Route path="/hosp_u" element={<BoardHospUpdate/>} />

      <Route path="/model_w" element={<BoardModelWrite/>} />
      <Route path="/model" element={<BoardModel/>} />
      <Route path="/model_u" element={<BoardModelUpdate/>} />

      <Route path="/def_w" element={<BoardDefectWrite/>} />
      <Route path="/defect" element={<BoardDefect/>} />
      <Route path="/def_u" element={<BoardDefectUpdate/>} />

      <Route path="/cx_w" element={<CustomerWrite/>} />
      <Route path="/cx_u" element={<CustomerUpdate/>} />

      <Route path="/cx" element={<Customer/>} />
      <Route path="/cx_view/:no" element={<CustomerView/>} />


      



      <Route path="/order_w" element={<Inspect/>} />
      <Route path="/doc_w/:form_no" element={<DocWrite/>} />
      <Route path="/doc_rw" element={<DocReWrite/>} />
      <Route path="/doc_main" element={<DocMain/>} />
      <Route path="/docs/:status" element={<Docs/>} />
      <Route path="/rdocs/:status" element={<DocsReceived/>} />
      <Route path="/doc_form" element={<Form/>} />
      <Route path="/docview" element={<DocView/>} />
      <Route path="/doc_u" element={<DocUpdate/>} />

      <Route path="/docsign/:doc_no" element={<DocSign/>} />
      <Route path="/doc_admin" element={<DocsAdmin/>} />
      <Route path="/setting/doc/:menu" element={<DocSettings/>} />
      <Route path="/form_add" element={<FormAdd/>} />
      <Route path="/form" element={<FormView/>} />
      <Route path="/form_edit" element={<FormEdit/>} />

      <Route path="/line_list" element={<LineList/>} />
      <Route path="/lastlines" element={<LastLines/>} />


      <Route path="/boardview/:b_no" element={<BoardView/>} />
      <Route path="/boardwrite" element={<BoardWrite/>} />
      <Route path="/notice" element={<Notice/>} />
      <Route path="/update" element={<BoardUpdate/>} />
      <Route path="/boardreply" element={<BoardReply/>} />
      <Route path="/data" element={<Data/>} />

      <Route path="/dataview/:b_no" element={<DataView/>} />
      <Route path="/datawrite" element={<DataWrite/>} />
      <Route path="/dataupdate" element={<DataUpdate/>} />
      <Route path="/datareply" element={<DataReply/>} />

      <Route path="/schedule" element={<Schedule/>} />
      <Route path="/schedule_w" element={<ScheduleWrite/>} />
      <Route path="/schedule_v" element={<ScheduleView/>} />
      <Route path="/schedule_u" element={<ScheduleUpdate/>} />

      <Route path="/calendar_w" element={<CalendarAdd/>} />
      <Route path="/calendar_u" element={<CalendarUpdate/>} />

      <Route path="/setting/calendar" element={<CalendarSetting/>} />


      <Route path="/work" element={<WorkMain/>} />
      <Route path="/work_w" element={<WorkWrite/>} />
      <Route path="/report/:periodical" element={<WorkReport/>} />
      <Route path="/d_report" element={<WorkReportDepartment/>} />
      <Route path="/workview" element={<WorkView/>} />
      <Route path="/work_u" element={<WorkUpdate/>} />
      <Route path="/worklist/:no" element={<WorkList/>} />
      <Route path="/add_workgrp" element={<WorkGroupAdd/>} />
      <Route path="/workgrp_u" element={<WorkGroupUpdate/>} />
      <Route path="/workrpt" element={<WorkReportView/>} />

      <Route path="/address" element={<AddressBook/>} />
      <Route path="/addrgrp_add" element={<AddressGroupAdd/>} />
      <Route path="/addr_list/:no" element={<AddressList/>} />
      <Route path="/addrgrp_u" element={<AddressGroupUpdate/>}/>
      <Route path="/addr_w" element={<AddressAdd/>}/>
      <Route path="/addr_u" element={<AddressUpdate/>}/>

      <Route path="/addrview" element={<AddressView/>}/>
      <Route path="/quick_addr" element={<AddressQuick/>}/>

      <Route path="/stats" element={<BoardStats/>}/>

      <Route path="/test" element={<ContractWrite/>} />



      </Route>
      <Route path="/org" element={<Org/>} />
      <Route path="/hr_card" element={<HRCard/>} />
      <Route path="/login" element={<Login/>} />
      <Route path="/checkline" element={<AddCheckLine/>} />
      <Route path="/contacts" element={<Contacts/>} />
      <Route path="/lines" element={<LineListComponent/>} />
      <Route path="/lineadd" element={<LineListAdd/>} />
      <Route path="/people" element={<People/>} />
      <Route path="/select" element={<SelectMember/>} />
      <Route path="/integrated_search" element={<AllSearch/>} />

      <Route path="/large_file" element={<BigFileUpload/>} />
      <Route path="/excel" element={<Excel/>} />
    </Routes>
  </Router>

  );
}};

export default App;