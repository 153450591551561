import { Navigate, Link, useNavigate } from "react-router-dom";
import moment from 'moment';
import React, { useState, useEffect, useRef } from "react";
import './css/home.css';
import userImg from "../components/img/user.png";
import Search from "./Search";
import axios from "axios";
import AutoComplete from "../components/AutoComplete";
import CoworkerInfo from "../components/CoworkerInfo";

import { IoIosMail, IoIosLink, IoIosArrowDroprightCircle } from "react-icons/io";
import { HiOutlineCake, HiSearchCircle, HiUsers } from "react-icons/hi";
import {
  format,
  addMonths,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  isSameMonth,
  isSameDay,
  parse,
  getMonth,
  getYear,
  addWeeks,
  addYears,
  addDays,
  subDays,
  getWeek,
  isWeekend,
  getDay,
  differenceInCalendarWeeks,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarYears
  
} from "date-fns";
import { MdEventNote, MdWork } from "react-icons/md";
import { RiCalendarTodoFill } from "react-icons/ri";

import { FaUserFriends } from "react-icons/fa";
axios.defaults.withCredentials = true; 

const Home = () => {
  const [profile, setProfile] = useState("");

  const [term, setTerm] = useState("");

  const [user, setUser] = useState([]);
  const [data, setData] = useState([]);
  const [unseen, setUnseen]=useState(0);

  const [unseenBoard, setUnseenBoard] = useState();
  const [signDocs, setSignDocs]=useState();
  const [tmrLeaves, setTmrLeaves] = useState([]);
  const [bookmarks, setBookmarks] = useState([]);
  const [boardData, setBoardData] = useState([]);
  const [workData, setWorkData] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [leaves, setLeaves] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  const [birthdays, setBirthdays] = useState([]);
  const [coworker, setCoworker] = useState();
  const [nextDate, setNextDate] = useState();

  const [info, setInfo]= useState([]);
  const [used, setUsed] = useState("");
  const [remain, setRemain] = useState("");
 


  const navigate = useNavigate();
  let weekdays = ["일","월","화","수","목","금","토"];

  function getNextBusinessDay(date) {
    // Copy date so don't affect original
    date = new Date(+date);
    // Add days until get not Sat or Sun
    do {
      date.setDate(date.getDate() + 1);
    } while (!(date.getDay() % 6))
    return date;
  }
  
  function compareOnKey(key, updown) {
    return function(a, b) {
      const eleA = a[key]; // ignore upper and lowercase
      const eleB = b[key]; // ignore upper and lowercase
      if (updown === true){
        if (eleA > eleB) {
          return -1;
        }
        if (eleA < eleB) {
          return 1;
        }
      }else{
        if (eleA < eleB) {
          return -1;
        }
        if (eleA > eleB) {
          return 1;
        }
      }
    
      // names must be equal
      return 0;
    };
}




function getuserleave(yr){
  axios
  .get("https://gw.thegmmedical.com:5003/api/getuser_leave/"+yr)
  .then(({ data }) => {
   
    setInfo(data[0]);
    let num = data[0].number;
    axios
      .get("https://gw.thegmmedical.com:5003/api/getuserleave_year/"+yr, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        var history=data;
        var sum = 0;
        for (let i=0;i<history.length;i++){
          if (history[i].vtype !== "1"){
            sum+=parseFloat(history[i].vtype);
          }else{
            sum += parseInt(history[i].days);
          }
        }
        var day = Math.trunc(sum);
        var time = sum % 1.0;
        setUsed(day+"."+(time/0.25)*2);
     
        var remainDay = Math.trunc(num - sum);
        var remainTime = (num - sum) % 1.0;
        setRemain(remainDay+"."+(remainTime/0.25)*2)
       


      });
     


  });
}


  useEffect(() => {

    

    let date= new Date();
    getuserleave(date.getFullYear());
    const BASE_CALENDAR_URL = "https://www.googleapis.com/calendar/v3/calendars";
    const BASE_CALENDAR_ID_FOR_PUBLIC_HOLIDAY =
      "holiday@group.v.calendar.google.com";
    const API_KEY = "AIzaSyAblXK6hywzoPW3Vp_4ASRDogoyEufpITA";
    const CALENDAR_REGION = "ko.south_korea";
    const currentYear = new Date().getFullYear();
    const timeMin = new Date(`${currentYear}-01-01`).toISOString();
    const timeMax = new Date(`${currentYear}-12-31`).toISOString();
   
    //https://calendar.google.com/calendar/embed?src=ko.south_korea.official%23holiday%40group.v.calendar.google.com&ctz=Asia%2FSeoul
    let next_date = new Date(+date);
    fetch(
      `${BASE_CALENDAR_URL}/${CALENDAR_REGION}.official%23${BASE_CALENDAR_ID_FOR_PUBLIC_HOLIDAY}/events?key=${API_KEY}&timeMin=${timeMin}&timeMax=${timeMax}`
    )
      .then((response) => response.json())
      .then((response) => {
        const formattedResponse = response.items
          .map(({ summary, start, end }) => ({ summary, start, end }))
          .sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
          let holidays = formattedResponse;
          //holidays.push({summary:"test", start:{date:'2024-08-20'}, end:{date:'2024-08-21'}});
         
// Copy date so don't affect original
// Add days until get not Sat or Sun
do {
  next_date.setDate(next_date.getDate() + 1);
} while (!(next_date.getDay() % 6))


          for (let i=0;i<holidays.length;i++){
            var from = new Date(holidays[i].start.date); // -1 because months are from 0 to 11
            var to = new Date(holidays[i].end.date); // -1 because months are from 0 to 11


            from.setHours(0,0,0,0);
            to.setHours(23,59,0,0);


            to.setDate(to.getDate() - 1);


   
            if (next_date >= from && next_date <= to){
              next_date.setDate(next_date.getDate() + 1);


            }
           
          }
   


          setNextDate(next_date);
          axios.get("https://gw.thegmmedical.com:5003/api/get_today_leaves/" + format(next_date, "yyyy-MM-dd"))
            .then((response) => {
             
             
              setTmrLeaves(response.data);
             
              //setSchedule(response.data);
          });
         
   
  });

    axios.get("https://gw.thegmmedical.com:5003/api/get_new_mails", {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {

      //console.log("unseen num...", data);
      //setUnseen(data.unseen)
    });


    axios.get("https://gw.thegmmedical.com:5003/api/unseen_num", {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {

      console.log("unseen num...", data);
      setUnseen(data.unseen)
    });


    axios.get("https://gw.thegmmedical.com:5003/api/unseen_board", {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {

      console.log("unseenboard", data);
      setUnseenBoard(data[0].unread)
    });

    

    axios.get("https://gw.thegmmedical.com:5003/api/get_today_leaves/" + format(new Date(), "yyyy-MM-dd"))
      .then((response) => {
        
        
        setLeaves(response.data);
       
        //setSchedule(response.data);
    });

    axios.get("https://gw.thegmmedical.com:5003/api/getbirthdays/" + format(new Date(), "MM"))
    .then((response) => {
      
     console.log("birthdays...", response.data);
      setBirthdays(response.data);
     
  });
    axios.get("https://gw.thegmmedical.com:5003/api/mainboard").then((response) => {
    let board = response.data;
    console.log("board...", board);
    let body  = document.getElementsByTagName("BODY")[0];let width = body.offsetWidth;

    for (let i=0;i<board.length;i++){
      if (width < 700){
        board[i].date_created = moment(board[i].date_created).format('MM-DD');

      }else{
        board[i].date_created = moment(board[i].date_created).format('MM-DD HH:mm:ss');
      }
    }
    setBoardData(board.splice(0,10));
    
    });
    axios.get('https://gw.thegmmedical.com:5003/api/getbookmarks',{
          
        })
      .then(function (json){
        console.log("result...", json.data);
        setBookmarks(json.data);
            //setUser(json.data[0]);
            //setProfile(json.data[0].profile_link);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    
      axios.get('https://gw.thegmmedical.com:5003/api/userinfo',{
          
        })
      .then(function (json){
        console.log("result...", json.data[0]);
            setUser(json.data[0]);
            setProfile(json.data[0].profile_link);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });

      axios.get('https://gw.thegmmedical.com:5003/api/get_main_docs')
    .then(function (response){
      console.log("hey result is!!!!!!!!!!!!", response.data);
      //setAbsent(response.data);
      //setLine(response.data[1]);
      setSignDocs(response.data.length);
     
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });

      fetch("https://gw.thegmmedical.com:5003/api/home_email", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log("네?", json);
        //setBoxes(json[0].boxes);
        setData(json);
        
        //console.log(boxes.boxes);
        //console.log(boxes.boxes);
      })
      .catch(() => {});

      axios.get("https://gw.thegmmedical.com:5003/api/getwork").then((response) => {
        //setNotice(response.data);
        let works = response.data.splice(0,6);
        works.sort(compareOnKey("end_date", true));
        console.log(works);
        setWorkData(works);
    
      });


      var schedule=[];
    var team_schedule=[];
    var user_schedule=[];
    var group_schedule=[];
    var invited_schedule=[];
    var final_schedule=[];
    var group_calendar, user_calendar;
    axios.get("https://gw.thegmmedical.com:5003/api/get_invited_calendar").then((response) => {
      // setNotice(response.data);
      group_calendar = response.data;
      console.log("groupcalenar ", response.data);

      
     });
     axios.get("https://gw.thegmmedical.com:5003/api/get_user_calendar").then((response) => {
      // setNotice(response.data);
      user_calendar = response.data;
       
     });
    axios.get("https://gw.thegmmedical.com:5003/api/get_schedule").then((response) => {
      schedule=response.data;
      final_schedule = final_schedule.concat(schedule);
      //setSchedule(response.data);
      axios
      .get("https://gw.thegmmedical.com:5003/api/get_team_schedule")
      .then((response) => {
        team_schedule=response.data;
        final_schedule = final_schedule.concat(team_schedule);
        //setTeamSchedule(response.data);
      axios
      .get("https://gw.thegmmedical.com:5003/api/get_user_schedule")
      .then((response) => {
        user_schedule=response.data;
        final_schedule = final_schedule.concat(user_schedule);
        //setSchedules(final_schedule);

        if (group_calendar.length>0){
           var valueArr = group_calendar.map(function(item){ return item.no });
          const data={
            group_nos: valueArr
          }
          axios.post("https://gw.thegmmedical.com:5003/api/get_group_schedule", data).then((response) => {
            console.log("gruop schedule", response.data);
          group_schedule=response.data;
          final_schedule = final_schedule.concat(group_schedule);

          });
        }
       

        axios
      .get("https://gw.thegmmedical.com:5003/api/get_invited_schedule")
      .then((response) => {
        console.log("invited", response.data);
        invited_schedule=response.data;
        if (response.data !==undefined){
          for (var i in invited_schedule) {
          invited_schedule[i].calendar_no = user_calendar[0].no;
          invited_schedule[i].color = user_calendar[0].color;
          }
        }
        
        const uniqueIds = [];
        
        final_schedule = final_schedule.concat(invited_schedule);
        console.log("invited", final_schedule);
        const unique = final_schedule.filter(element => {
          const isDuplicate = uniqueIds.includes(element.s_id);
        
          if (!isDuplicate) {
            uniqueIds.push(element.s_id);
        
            return true;
          }
        
          return false;
        });
        let selectedDateText=format(new Date(), "yyyy-MM-dd");
        console.log("sd...", selectedDateText);
        let final_array =[];
        for (let i=0;i<unique.length;i++){
          if (unique[i].start_hour < 10){
            unique[i].start_hour = "0"+unique[i].start_hour;
          }
          if (unique[i].start_min < 10){
            unique[i].start_min = "0"+unique[i].start_min;
          }
          if (unique[i].end_hour < 10){
            unique[i].end_hour = "0"+unique[i].end_hour;
          }
          if (unique[i].end_min < 10){
            unique[i].end_min = "0"+unique[i].end_min;
          }
          /*let d1 = unique[i].start_date.split("-");
          let c = selectedDateText.split("-");
          let d2 = unique[i].end_date.split("-");

          let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
          let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
          let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);

          
          if (check >= from && check <= to) {
            final_array.push(unique[i]);
            
          }*/

        }

        
          let day = [];
          let schedules = unique;
          console.log("schedule...........", schedules);
      
            let temp=[];
            
            for (let j = 0; j < schedules.length; j++) {
                //console.log("야");
                let d1 = schedules[j].start_date.split("-");
                let c = selectedDateText.split("-");
                let d2 = schedules[j].end_date.split("-");
      
                let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
                let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
                let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
      
                if (check >= from && check <= to) {
                    if (schedules[j].is_repeat === 1) {
                      if (schedules[j].rep_cycle === "everyday") {
                        let cycle_num = schedules[j].rep_cycleN;
                        let times = schedules[j].times;
                        let diff_week = differenceInCalendarDays(check, from);
          
                        //if (())
                        if (schedules[j].rep_end === "times"){
                          let total = times * cycle_num;
                          let maxdate = addDays(from, total);
                          if (check.getTime() < maxdate.getTime()){
                            if ((diff_week%cycle_num)===0){
          
                              temp.push(schedules[j]);
                              
          
          
                            }
                          }
                        }else{
                          if ((diff_week%cycle_num)===0){
                            temp.push(schedules[j]);
                          }
                        }
                      }else if (schedules[j].rep_cycle === "weekly_weekday"){
                        let cycle_num = schedules[j].rep_cycleN; // 주기
                        let diff_week = differenceInCalendarWeeks(check, from);
          
                        if (schedules[j].rep_end === "times"){
                          let times = schedules[j].times;
                          let total = times * cycle_num;
                          let maxdate = addWeeks(from, total);
                          if (check.getTime() < maxdate.getTime()){
          
                        if (!isWeekend(check) && ((diff_week%cycle_num)===0)){
                          temp.push(schedules[j]);
                        }
                        }
                        }else{
          
                          if (!isWeekend(check) && ((diff_week%cycle_num)===0)){
                            temp.push(schedules[j]);
                          }
                        }
                      }else if (schedules[j].rep_cycle === "weekly"){
          
                        let cycle_num = schedules[j].rep_cycleN;
                        let rep_weekday = schedules[j].rep_week_day;
                        let diff_week = differenceInCalendarWeeks(check, from);
                        let letterDays = ["sun", "mon", "tue", "wed", "thr", "fri", "sat"];
          
                        if (schedules[j].rep_end === "times"){
                          let times = schedules[j].times;
                          let total = times * cycle_num;
                          let maxdate = addWeeks(from, total);
                          if (check.getTime() < maxdate.getTime()){
                        
                          if (letterDays[getDay(check)]===rep_weekday && ((diff_week%cycle_num)===0)){
                            temp.push(schedules[j]);
                          }
                          }
                        }else{
                          if (letterDays[getDay(check)]===rep_weekday && ((diff_week%cycle_num)===0)){
                            temp.push(schedules[j]);
          
                          }
                        }
          
                      }else if (schedules[j].rep_cycle === "monthly"){
                        let cycle_num = schedules[j].rep_cycleN;
                        let rep_type = schedules[j].rep_type;
                        let rep_month_type = schedules[j].rep_month_type;
                        let diff_week = differenceInCalendarMonths(check, from);
                        
                        if (schedules[j].rep_end === "times"){
                          let times = schedules[j].times;
                          let total = times * cycle_num;
                          let maxdate = addMonths(from, total);
                          if (check.getTime() < maxdate.getTime()){
                          
                            if (rep_month_type === "date" && c[2]== rep_type && ((diff_week%cycle_num)===0)){
                              temp.push(schedules[j]);
                            }else if (rep_month_type === "nthday" && ((diff_week%cycle_num)===0)){
                              temp.push(schedules[j]);
                            }
                          }
                        }else{
                          if (rep_month_type === "date" && c[2]== rep_type && ((diff_week%cycle_num)===0)){
                            temp.push(schedules[j]);
                          }else if (rep_month_type === "nthday" && ((diff_week%cycle_num)===0)){
                            temp.push(schedules[j]);
                          }
                        }
                      }else if (schedules[j].rep_cycle === "yearly"){
                        let cycle_num = schedules[j].rep_cycleN;
                        let rep_type = schedules[j].rep_type;
                        let rep_month_type = schedules[j].rep_month_type;
                        let diff_week = differenceInCalendarYears(check, from);
                        
                        if (schedules[j].rep_end === "times"){
                          let times = schedules[j].times;
                          let total = times * cycle_num;
                          let maxdate = addYears(from, total);
                          if (check.getTime() < maxdate.getTime()){
                            if (rep_month_type === "date" && parseInt(c[2])== rep_type.split("-")[1] && parseInt(c[1]) ==  rep_type.split("-")[0] && ((diff_week%cycle_num)===0)){
                              temp.push(schedules[j]);
                            }else if (rep_month_type === "nthday" && c[1] ==  d1[1] && ((diff_week%cycle_num)===0)){
                              let nth = rep_type.split("-");
                              var days_en = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
                              d    = day ? day instanceof Date ? day : new Date(day) : new Date(),
                              date = d.getDate(),
                              getday  = d.getDay(),
                              n    = Math.ceil(date / 7);
                              if (nth[0]==n && nth[1] == days_en[getday]){
                                temp.push(schedules[j]);
                              }
                            }
                          }
                        }else{
          
                          if (rep_month_type === "date" && parseInt(c[2])===parseInt(rep_type.split("-")[1]) && parseInt(c[1]) === parseInt(rep_type.split("-")[0]) && ((diff_week%cycle_num)===0)){
                            temp.push(schedules[j]);
                          }else if (rep_month_type === "nthday" && c[1] ==  d1[1] && ((diff_week%cycle_num)===0)){
                            let nth = rep_type.split("-");
                            var days_en = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
                            d    = day ? day instanceof Date ? day : new Date(day) : new Date(),
                            date = d.getDate(),
                            getday  = d.getDay(),
                            n    = Math.ceil(date / 7);
                            if (nth[0]==n && nth[1] == days_en[getday]){
                              temp.push(schedules[j]);
                            }
                          }
                        }
                      }
                    }else{
                      temp.push(schedules[j]);
                    }
                console.log("tmp......", temp);
                }
            }
      
            
            final_array = temp.splice(0,6);


        setSchedules(final_array);
          console.log("day..........", day);
        
      
     

        

        
      });
          
          
        });

        
      });
      });

  }, []);

  function mail_list(){
    let array = [];
    let index = data.length-1;
    let base=data.length;
    if (data.length>10){
      index=data.length-1;
      base = index-10;
    
    
      for (let i = 0; i <10; i++) {
        var folder = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
        var href = "/all/view/"+ data[i].uid;
        var formattedDate="";
        var d = new Date();
        var today_year = d.getFullYear();
        var year = moment(data[i][2]).format('YYYY');

        let body  = document.getElementsByTagName("BODY")[0];let width = body.offsetWidth;
        if (today_year === year){
          if (width < 700){
            formattedDate = moment(data[i].received_date).format('MM-DD');

          }else{
            formattedDate = moment(data[i].received_date).format('MM-DD HH:mm:ss');

          }
        }else{

          if (width < 700){
            formattedDate = moment(data[i].received_date).format('MM-DD');

          }else{
            formattedDate = moment(data[i].received_date).format('YYYY-MM-DD HH:mm:ss');

          }
        }
        var from = data[i].from_addr;
   
   
      
        if (data[i].from_name !==null && data[i].from_name !==""){
         from = data[i].from_name;
        }

       let seen, star;
      
       from = from.replace('["','');
       from = from.replace('"]','');
        array.push(<div className="item">
      
        {star}
        
          <div style={{display:"flex", width:"100%", position:"relative"}}>
          <div className="froms">
            {from}
          </div>
          <Link to={href} state={{ date: formattedDate }}><div className="subjects">
            {seen}
            {data[i].tags.includes("Seen") ?
              <span>{data[i].subject}</span>
            : <span style={{fontWeight:"bold"}}>{data[i].subject}</span>
            }
            
            
            
          </div>
          </Link>


          <div className="dates" style={{position:"absolute", right:"0"}}>
            {formattedDate} 
          </div>

          </div>
          

          
          
        </div>);
  
        
      }
    }else{
      for (let i = 0; i <data.length; i++) {
        var folder = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
        var href = "/all/view/"+ data[i].uid;
        var formattedDate="";
        var d = new Date();
        var today_year = d.getFullYear();
        var year = moment(data[i][2]).format('YYYY');

        let body  = document.getElementsByTagName("BODY")[0];let width = body.offsetWidth;
        if (today_year === year){
          if (width < 700){
            formattedDate = moment(data[i].received_date).format('MM-DD');

          }else{
            formattedDate = moment(data[i].received_date).format('MM-DD HH:mm:ss');

          }
        }else{

          if (width < 700){
            formattedDate = moment(data[i].received_date).format('MM-DD');

          }else{
            formattedDate = moment(data[i].received_date).format('YYYY-MM-DD HH:mm:ss');

          }
        }
        var from = data[i].from_addr;
   
   
      
        if (data[i].from_name !==null && data[i].from_name !==""){
         from = data[i].from_name;
        }

       let seen, star;
       from = from.replace('["','');
       from = from.replace('"]','');
       
        array.push(<div className="item">
      
        {star}
        
          <div style={{display:"flex", width:"100%", position:"relative"}}>
          <div className="froms">
            {from}
          </div>
          <Link to={href} state={{ date: formattedDate }}><div className="subjects">
            {seen}
            <span>{data[i].subject}</span>
          </div>
          </Link>


          <div className="dates" style={{position:"absolute", right:"0"}}>
            {formattedDate} 
          </div>

          </div>
          

          
          
        </div>);
  
        
      }
    }
      return array;
  }
  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      navigate("/integrated_search", {state: { term : term}});

    }
  }

  const togglePopup = () => {
    setShowPopup(!showPopup);
};
  return (
    <div className="wrapper">
      <div className="content-wrap dashboard-wrap">

      {showPopup ? (
  <CoworkerInfo data={coworker} closePopup={() => togglePopup()} style={{top:"120px"}}/>
) : null}


     <div className="dashboard">
      <div className="col1">
        <div className="profile">
        <div className="custom-file-upload">  

{profile !=="" ? 
<div className="img-wrap img-upload"><img for="photo-upload" src={profile} alt="profile_pic"/>
</div>
: <img for="photo-upload" src={userImg} alt="profile_pic"/>
}

</div> 

<div className="user_info"><span>{user.name}</span><span>{user.d_name}</span> <span>{user.job_name}</span></div>
<Link to="/list/INBOX" className="new_alert">새 메일 <span>{unseen} </span></Link>
<Link to="/doc_main" className="new_alert">결재 예정 문서 <span>{signDocs} </span></Link>
<Link to="/schedule" state={{type : "daily"}}className="new_alert">오늘의 일정 <span>{schedules.length} </span></Link>
<div className="new_alert">잔여 연차<span>{remain}일 / {info!==undefined && info.number}일</span></div>
</div>


<div className="profile2">


<div className="clock"> <div className="today">TODAY</div> 
<span style={{lineHeight:"20px"}}>{moment(new Date()).format("YYYY-MM-DD")} ({moment(new Date()).format("ddd")}) {moment(new Date()).format("HH:mm")}</span></div>
<div className="dashboard_buttons">
  <button onClick={() => popupwindow("/org", "조직도", 800, 650)} alt=''>조직도</button>
  <button style={{padding:"0"}}><Link to="/schedule" 
  style={{display:"block",width:"100%", height:"100%", padding: "10px 20px", boxSizing:"border-box"}}>월간 일정</Link></button>
</div>
        <div className="quick component">
        <h3><IoIosArrowDroprightCircle/>메뉴 바로가기</h3>
        <div>
          {bookmarks.map((element,index)=>(
            <Link className="link item" to={element.path} state ={{ boardName : element.name}}><IoIosLink/><span style={{marginLeft:"5px"}}>
            {element.bookmark}</span></Link>
          ))}
        
        </div>
        </div>



</div>



     

      </div>
      <div className="col2">
        <div className="email-wrap">
        <h3><IoIosMail/>이메일<span className="unread">{unseen} </span></h3> 
      <div className="email_list">

      {data.length>0 && (
       mail_list() 
      )}

      {data.length===0 && (
        <div className="item" style={{height:"180px", justifyContent:"center", alignItems:"center"}}>
          <span>메일이 없습니다.</span>
        </div>

      )}

      </div>
      </div>

      <div className="board-wrap component">
        <h3><MdEventNote/>게시판<span className="unread">{unseenBoard} </span></h3> 
      <div className="board_list">

        {boardData.map((element, index)=>(
          <div className="item">
           <div className="title" style={{width:"60%", display:"flex", alignItems:"center"}}>
           <div className="notice new" style={{textAlign:"center"}}>
            <Link to={"/board"} state={{boardName:element.bg_name, bg_no : element.bg_no}}style={{color:"white"}}>{element.bg_name}</Link></div>
            <Link to={"/boardview/"+element.b_no} state={{bg_name : element.bg_name, bg_no: element.bg_no}}><div className="subject">{element.subject}</div></Link>
           </div>
           <div className="author">{element.name}</div>
           <div className="date">{element.date_created}</div>
          </div>
        ))}
        

        {boardData.length===0 && (
        <div className="item" style={{height:"180px", justifyContent:"center"}}>
          <span>게시글이 없습니다.</span>
        </div>
        )}
     
      </div>
      </div>
      </div>
      <div className="col3">
      <div className="work search">
      <h3><HiSearchCircle/>통합검색</h3>
      <table width="100%">
		<tr>
      <input type="text" name="SearchTxt" size="40" class="tform_big" value={term} onChange={(e)=>setTerm(e.target.value)} onKeyDown={handleKeyDown}/>
        <Link to="/integrated_search" state={{term: term}}>검색</Link>
        </tr>
		</table>
    </div>


    

    <div className="work search search_user pc" style={{display:"block"}}>
      <h3><HiSearchCircle/>사원검색</h3>
      <table width="100%">
		<tr>
    <AutoComplete onChange={(value) => {setCoworker(value);console.log("hihi", value)}}/> 
    <button style={{width:"15%"}} onClick={()=>{
      if (coworker!==undefined){togglePopup()}}}>보기</button>

    </tr>
		</table>
    </div>


        <div className="work component">
        <h3><MdWork/>업무진행현황</h3>
        <div className="work_list">
{workData.map((ele, idx)=>(
    <div>
    <div className="item">

      <div className="title">
      <Link
                                        to="/workview"
                                        state={{ subject: ele.subject, importance: ele.importance, no: ele.no }}
                                      >
                                        {ele.subject}
                                      </Link>
      </div>

      
        {new Date(ele.end_date) < new Date() && (
          <div className="d-day" style={{background:"#a6a6a6"}}><span>D+{differenceInCalendarDays(new Date(), new Date(ele.end_date))}</span></div>
        )}
        {new Date(ele.end_date) >= new Date() && (
        <div className="d-day"><span>D-{differenceInCalendarDays(new Date(ele.end_date), new Date())}</span></div>
        )}
      <div className="duedate">
        <span>{ele.end_date.substring(5)}</span>
      </div>
      {ele.status === "계획" && (
                                        <div
                                          class="status"
                                          style={{
                                            backgroundColor: "#c3bef0",
                                            width: "30px",
                                          }}
                                        >
                                          {ele.status}
                                        </div>
                                      )}
                                      {ele.status === "진행" && (
                                        <div
                                          class="status"
                                          style={{
                                            backgroundColor: "#8dc6ff",
                                            width: "30px",
                                          }}
                                        >
                                          {ele.status}
                                        </div>
                                      )}

                                      {ele.status === "지연" && (
                                        <div
                                          class="status"
                                          style={{
                                            backgroundColor: "#ffb5b5",
                                            width: "30px",
                                          }}
                                        >
                                          {ele.status}
                                        </div>
                                      )}

                                      {ele.status === "완료" && (
                                        <div
                                          class="status"
                                          style={{
                                            backgroundColor: "#9bf4d5",
                                            width: "30px",
                                          }}
                                        >
                                          {ele.status}
                                        </div>
                                      )}
                                      
                                      {ele.status === "취소" && (
                                        <div
                                          class="status"
                                          style={{
                                            backgroundColor: "#d3d6db",
                                            width: "30px",
                                          }}
                                        >
                                          {ele.status}
                                        </div>
                                      )}
    </div>
    </div>

))}
    
    {workData.length===0 && (
        <div className="item" style={{height:"100px", justifyContent:"center", alignItems:"center"}}>
          <span>업무가 없습니다.</span>
        </div>

      )}


     
      </div>
        </div>

        <div className="schedule component">
        <div className="schedules">
        <h3><RiCalendarTodoFill/>내 캘린더 일정</h3>        
        
        <div className="schedule_list">
        {schedules.map((ele,idx)=>(
        <div className="item">
      
        <div className="title">
          <Link to="/schedule_v" state={{schedule: ele}}>{ele.subject}</Link>
        </div>
      
        <div className="duedate">
        <span>{ele.end_date}</span><span> {ele.start_hour}:{ele.start_min}-{ele.end_hour}:{ele.end_min}</span>
        </div>
     
      
      </div>
      ))}
        {schedules.length===0 && (
        <div className="item" style={{height:"100px", justifyContent:"center", alignItems:"center"}}>
          <span>일정이 없습니다.</span>
        </div>

      )}

      </div>
        </div>
        
        </div>

        <div className="birthday component" style={{width:"100%"}}>
        <h3><HiOutlineCake/>이달의 생일자 ({birthdays.length}명) </h3>
          {birthdays.map((ele, idx)=>(
            <div className="item"><span>[{ele.birthdate.slice(-2)}일] {ele.name} {ele.job_name}</span></div>
          ))}
{birthdays.length===0 && (
            <div className="item" style={{height:"60px", alignItems:"center", justifyContent:"center"}}><span>생일자가 없습니다.</span></div>
          )}
        </div>
        

      <div className="absence" style={{display:"flex", width:"100%", justifyContent:"space-between"}}>
       
       
       


       <div className="birthday component">
       <h3><HiUsers/> {moment(new Date()).format("MM월 DD일")} ({weekdays[moment(new Date()).day()]}) 부재자 ({leaves.length}명) </h3>
       <div>
         {leaves.length===0 && (
           <div className="item" style={{height:"60px", alignItems:"center", justifyContent:"center"}}><span>부재자가 없습니다.</span></div>
         )}
         {leaves.map((ele, idx)=>(
           <div className="item"><span>{ele.name}-
           [{ele.vtype==1&&(<span>연차</span>)}
           {(ele.vtype == "0.5" && ele.vrange == "2") &&(<span> 오후반차</span>)}
                 {(ele.vtype == "0.5" && ele.vrange == "1") &&(<span>오전반차</span>)}
           {ele.vtype==0.25&&(<span>반반차</span>)}{ele.vtype==0&&(<span>공가</span>)}]
           &nbsp;{moment(ele.start_date).format('YYYY-MM-DD')}~{moment(ele.end_date).format('YYYY-MM-DD')}</span></div>
         ))}
    
       </div>
       </div>


       <div className="birthday component">
       <h3><HiUsers/> {moment(nextDate).format("MM월 DD일")} ({weekdays[moment(nextDate).day()]}) 부재자 ({tmrLeaves.length}명) </h3>
       <div>
         {tmrLeaves.length===0 && (
           <div className="item" style={{height:"60px", alignItems:"center", justifyContent:"center"}}><span>부재자가 없습니다.</span></div>
         )}
         {tmrLeaves.map((ele, idx)=>(
           <div className="item"><span>{ele.name}-
           [{ele.vtype==1&&(<span>연차</span>)}
            
            {(ele.vtype == "0.5" && ele.vrange == "2") &&(<span> 오후반차</span>)}
                 {(ele.vtype == "0.5" && ele.vrange == "1") &&(<span>오전반차</span>)}

            {ele.vtype==0.25&&(<span>반반차</span>)}{ele.vtype==0&&(<span>공가</span>)}]
           &nbsp;{moment(ele.start_date).format('YYYY-MM-DD')}~{moment(ele.end_date).format('YYYY-MM-DD')}</span></div>
         ))}
    
       </div>
       </div>




       </div>











      </div>
     </div>
     </div>
    </div>

  );
};

export default Home;