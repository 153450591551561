
import { NavLink, Link, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import DataList from "../components/side/DataList";
import axios from "axios";
import moment from "moment";

import "./css/board.css";
import {Cookies} from "react-cookie";
import { HiPencilAlt } from "react-icons/hi";
import { BsPin } from "react-icons/bs";
import {GiHamburgerMenu} from "react-icons/gi";
import Pagination from "./Pagination";
import { RiArrowGoBackFill } from "react-icons/ri";


const cookies = new Cookies();

var currentData=[];
const Board = () => {
  const [data, setData] = useState([]);
    
  const [history, setHistory] = useState(false);

  const [isActive, setActive] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [parent, setParent] = useState("");
  const [name, setName] =useState("");
  const [num, setNum] = useState(0);
  const [lev, setLev] = useState(0);
  const [selected, setSelected]= useState();
  const [search, setSearch] = useState(null);
  const [boardN, setBoardN] = useState("");
  const [boardNum, setBoardNum] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [options, setOption] = useState("all");
  const [filterData, setfilterData] = useState([]);
  const [boardGroup, setBoardGroup] = useState([]);
  const [teamBoard, setTeamBoard] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState("");
  const [notice, setNotice] = useState([]);
  const [isEmpty, setEmpty] = useState(false);
  const [isPage, setIsPage] = useState(false);
  const [isloggedIn, setLoggedIn] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isSort, setIsSort] = useState(false);
  const offset = (page - 1) * limit;
  const { state } = useLocation();

  const [modalOpen, setModalOpen] = useState(true);
  const [popUpContent, setPopUpContent] = useState([]);
  const [hasCookie, setHasCookie] = useState(true);
  const [main, setMain] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [boardAdmin, setBoardAdmin] = useState();
  const [user, setUser] = useState([]);

    
  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };


    
    const onChangeSearch = (e) => {
      e.preventDefault();
      setSearch(e.target.value);
    };
    const setCookie = (name, value, options) => {
      return cookies.set(name, value, {...options}); 
   }
   const getCookie = (name) => {
    return cookies.get(name); 
   }
    const getExpiredPopUpDate = (days) => {
      const date = new Date();
      date.setDate(date.getDate() + days); // 일
      return date;
    };
  
    const closeModalUntilExpires = () => {
      // if (!appCookies) return;
      const expires = getExpiredPopUpDate(1); // 1일
      setCookie('MODAL_EXPIRES', true, { path: '/', expires });
      setCookie('ModalNum', popUpContent.no, { path: '/', expires });

      setModalOpen(false);
    };

/*
    const getContracts = async (params) => {
      const res = await axios({
        method: "POST",
        data: params,
        withCredentials: true,
        url: "https://gw.thegmmedical.com:5003/api/get_contracts",
      })
      setfilterData(res.data)
          setData(res.data);
    };
*/

    useEffect(() => {
      

    
      if (state !==null){
        if(state.boardName!==undefined) {
        currentData = state;

        
        let year="";
        let group="";

        if (!isNaN(parseInt(state.boardName))){
          console.log("number");
          year = state.boardName;
          group = state.parentName;
          setBoardN(state.parentName + "-" + state.boardName);

        }else{
          console.log("not a number");
          group = state.boardName;
          setBoardN(state.boardName);

        }

        const params = {
          year : year,
          group: group
        }


        axios.post("https://gw.thegmmedical.com:5003/api/get_contracts",{
          data: params
        }).then((response) => {
          console.log("getboard", response.data);
    
          setfilterData(response.data)
          setData(response.data);
          
       
        });

        //getContracts(params);
    
    
        }
      }

      axios.get('https://gw.thegmmedical.com:5003/api/getuser')
      .then(function (response){
      
        setUser(response.data[0]);
  
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
  


 
     
      

axios.get('https://gw.thegmmedical.com:5003/api/getdatagroup',{})
.then(function (response){

  
setBoardGroup(response.data.filter(item=>item.bg_type ==="board"));

})
.catch(function (error){
  console.log(error);
})
.then(function (){
//always executed
});
axios.get('https://gw.thegmmedical.com:5003/api/get_teamdata',{
      
})
.then(function (response){

  setTeamBoard(response.data);
    
})
.catch(function (error){
console.log(error);
})
.then(function (){
//always executed
});




  



 












axios.get('https://gw.thegmmedical.com:5003/api/check_boardadmin')
      .then(function (response){
        console.log("user is", response.data);
        if (response.data.length>0){
          setBoardAdmin(true);
        }
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });



    }, [state]);


   

  const addBookmark = () => {
    var result = window.confirm("즐겨찾기 게시판에 추가하시겠습니까?");
    if(result){
      const data ={
        name: boardN,
        num : boardNum,
        table : "data"
      }
      console.log(data);
      axios.post('https://gw.thegmmedical.com:5003/api/add_bookmark', data)
    .then(function (response){
      console.log(response);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
  };
  const addBookmarkPage = () => {
    var result = window.confirm("메뉴 바로가기에 추가하시겠습니까?");
    if(result){
      const data ={
        name: boardN,
        num : boardNum,
        link : window.location.pathname,
        bookmark : boardN + " 자료실"
      }
      console.log(window.location.pathname)
      console.log(data);
      axios.post('https://gw.thegmmedical.com:5003/api/add_bookmark_page', data)
      .then(function (response){
        console.log(response);
        alert("메뉴 바로가기에 추가되었습니다.");

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }
  };
  const selectHandle = (e) => {
    console.log("changed", e.target.value);

    setOption(e.target.value);
  };

  const selectBoard = (e) => {

    setSelectedBoard(e.target.value);
  };
  const onSearch = (e) => {
    e.preventDefault();
    setIsSearch(true);
    let temp = data;
   
    if (options === "name") {
      const filtered = temp.filter((itemList) => {
        return itemList.name.toUpperCase().includes(search.toUpperCase());
      });
      setfilterData(filtered);
      if (filtered.length === 0) {
        setEmpty(true);
        setIsPage(false);
      } else {
        setEmpty(false);
        if (filtered.length <= 10) {
          setIsPage(false);
        } else {
          setIsPage(true);
        }
      }
    } else if (options === "all"){
      
        
      const filtered = temp.filter((itemList) => {
        var tmp = "";
        tmp+=itemList.name;
        tmp+=itemList.contractor;
        tmp+=itemList.group_name;
        return tmp.toUpperCase().includes(search.toUpperCase());
      });


      console.log("filtered.......", filtered);
      setfilterData(filtered);
      if (filtered.length === 0) {
        setEmpty(true);
        setIsPage(false);
      } else {
        setEmpty(false);
        if (filtered.length <= 10) {
          setIsPage(false);
        } else {
          setIsPage(true);
        }
      }
    }else if (options === "contractor") {
      const filtered = temp.filter((itemList) => {
        return itemList.contractor.toUpperCase().includes(search.toUpperCase());
      });
      setfilterData(filtered);
      if (filtered.length === 0) {
        setEmpty(true);
        setIsPage(false);
      } else {
        setEmpty(false);
        if (filtered.length <= 10) {
          setIsPage(false);
        } else {
          setIsPage(true);
        }
      }
    }else if (options === "group_name") {
      const filtered = temp.filter((itemList) => {
        return itemList.group_name.toUpperCase().includes(search.toUpperCase());
      });
      setfilterData(filtered);
      if (filtered.length === 0) {
        setEmpty(true);
        setIsPage(false);
      } else {
        setEmpty(false);
        if (filtered.length <= 10) {
          setIsPage(false);
        } else {
          setIsPage(true);
        }
      }
    }
  };
  const sortByGroupName = (name) =>{
    if (isSort===false){
      let temp = filterData;
      const filtered = temp.filter((itemList) => {
        return itemList.group_name == name;
      });
  
      setfilterData(filtered);
    }else{
      setfilterData(data);
    }
  
  }
  const activateEnter = (e) =>{
    if(e.key === "Enter") {
      onSearch(e);
    }

  }
  return (
    <div className="wrapper">
  
<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} 

setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
  
        <div className="side-mobile-menu">
<DataList changeBoard={(value)=>{
  console.log("boardvalue,,,", value);
    setfilterData(value);setData(value);setMain(false);
  
  
        if (value.length === 0) {
          setEmpty(true);
        } else {
          setEmpty(false);
          if (value.length <= 10) {
            setIsPage(false);
          } else {
            setIsPage(true);
          }
        }}} changeNotice={(value)=>{setNotice(value);}} changeBoardN={(value)=>{setBoardN(value);}} 
        changeBoardNum={(value)=>{
          setSidebar(false);
        }}
        
        
        
        />
            </div>
      )}
     <div className="content-wrap">
     
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start"}}>
        {/*<div className="menu-icon" onClick={handleShowNavbar}>
          <GiHamburgerMenu/>
        </div>*/}
        <div className="side-menu">
        <DataList changeBoard={(value)=>{}} changeNotice={(value)=>{setNotice(value);}} changeBoardN={(value)=>{console.log("boardN", value);setBoardN(value);}} 
        changeBoardNum={(value)=>{
        

        }}/>
    
    </div>
        <div className="main_content">
        
        <div className="board_wrapper">
        {/*}  <form name="form1" id="form1" method="post">*/}
          <div className="board_container">
            <div id="content" className="content">
                          
              <div className="board_top">
           

<div className="btns">

{boardN !=="" && (<Link to="/test" state={{name: state.boardName, parent: state.parentName}}><div className="board_wrt" style={{marginLeft:"10px", padding:"6px 10px", marginBottom:"0"}}>
<HiPencilAlt/>새 글쓰기</div></Link>)}

</div>           

<div className="board_header">{boardN}</div>  
                             <div className="bt_srch" style={{display:"flex", justifyContent:"end"}}>
                             {isSearch && (
                                  <button onClick={(e)=>{
                                    e.preventDefault();
                                    setIsSearch(false);
                                    setfilterData(data);
                                  }}>
                                    <RiArrowGoBackFill/> 
                                  </button>
                                  )}
                                 

                                <div className="bts_slct">

                                  <select
                                    id="u_shcate"
                                    className="select"
                                    value={options}
                                    onChange={selectHandle}
                                  >
                                    <option value="all">전체</option>
                                    <option value="name">계약명</option>
                                    <option value="group_name">계약분류</option>
                                    <option value="contractor">계약상대</option>

                                  </select>


                                </div>
                                <div className="bt_input">
                                  <input
                                    type="text"
                                    id="search_content"
                                    name="search_content"
                                    value={search || ''}
                                    placeholder="검색어를 입력하세요"
                                    onChange={onChangeSearch}

                                    onKeyDown={(e)=>activateEnter(e)}
                                  />
                                </div>


                                <button
                                  className="search_btn"
                                  value="검색"
                                  onClick={onSearch}
                                >
                                  검색
                                </button>
                              </div>



                          </div>

{history === false ? 
  <div className="board_list">
                         
                          
                  <table class="table table-hover bd-b">
									<thead class="thead-dark">
									<tr class="tx-center">
										<th class="wd-80 xl-hidden" style={{width:"5%"}}>번호</th>
                  
										<th class="" style={{width:"15%"}}>계약명</th>

                    <th class="wd-120 xl-hidden">분류</th>

                    <th class="wd-120 xl-hidden">년도</th>

										<th class="wd-120 xl-hidden">계약상대</th>
									</tr>
									</thead>
									<tbody>
                  {filterData.slice(offset, offset + limit).map(
                                    ({
                                      no, name, year, contractor, group_name
                                    }) => (
                                      ( <tr key={no}>
                                      

                                        <td>
                                          {no}
                                        </td>
                                       
                                        <td>
                                        
                                        <Link to={"/test2/"+no} style={{fontWeight:"bold"}}>{name}</Link>

                                        
                                        
                                        
                                        </td>
                                       
                                        <td>
                                        <span style={{padding:"2px 5px", background:"#cefaff", margin:"5px 0", borderRadius:"5px"}}
                                        onClick={()=>{sortByGroupName(group_name); setIsSort(!isSort)}}>
                                          {group_name}
                                        </span>
                                        </td>
                                        <td>
                                        {year}
                                        </td>
                                        <td>
                                        {contractor}
                                        </td>
                                      
                                      </tr>)
                                     
                                    )
                                  )}
                                 
                                  
                              

									</tbody>
								</table>
                            <table>

                              <colgroup>
                                <col className="m_dp_n table_no" />
                                <col className="" />
                                <col className="ta_l" />
                                <col className="m_dp_n table_date" />
                              </colgroup>
                              {/*<thead>
                        <tr>
                            <th className="m_dp_n table_no" scope="col">NO.</th>
                            <th scope="col"></th>
                            <th className="ta_l" scope="col">제목</th>
                            <th className="m_dp_n table_date" scope="col">날짜</th>
                        </tr>
                    </thead>*/}
                              <tbody>
                               
                              </tbody>
                            </table>

                            {filterData.length===0 && (
                                  <div style={{ textAlign: "center", padding:"50px 0", width:"100%", borderBottom:"1px solid #ddd" }}>
                                    <p>게시물이 없습니다.</p>
                                  </div>
                                )}

                            <div style={{width:"100%",textAlign:"right"}}>
                    {isloggedIn && (
                      <Link to="write"
                        style={{
                          padding: "10px 30px",
                          backgroundColor: "#555",
                          color: "white",
                          textAlign: "center",
                          position:"relative",
                          display:"inline-block",
                          width:"105px",
                          cursor: "pointer",
                        }}
                      >
                        <HiPencilAlt/>글쓰기
                      </Link>
                    )}
                    </div>
                  </div>
:



<div className="board_list">
                         
                          
<table class="table table-hover bd-b">
<thead class="thead-dark">
<tr class="tx-center">
  <th class="wd-80 xl-hidden" style={{width:"5%"}}>번호</th>
  {main && (
                       <th style={{width:"8%"}}>
                      게시판
                      </th>

                    )}
  <th class="" style={{width:"25%"}}>제목</th>
  <th class="wd-150 xl-hidden">작성자</th>
  <th class="wd-120 xl-hidden">분류</th>

  <th class="wd-120 xl-hidden">작성일</th>
</tr>
</thead>
<tbody>
{filterData.filter((item)=>(item.end_date !=="" && item.end_date !== null && item.end_date < moment(new Date()).format("YYYY-MM-DD")))
                .map(
                  ({
                    b_no,bg_name,
                    subject,name,job_name, d_name, 
                    date_created, category,
                    bg_no, is_notice
                  }) => (
                    is_notice ===1 &&( <tr key={b_no} className="noticeboard">
                    

                      <td>
                      <span>공지</span>
                      </td>
                      {main && (
                       <th>
                      <span style={{border:"1px solid #ddd", padding:"2px"}}>{bg_name}</span>
                      </th>

                      )}
                      <td>
                      <Link to={"/dataview/"+b_no} state={{boardName : bg_name, bg_no: bg_no}}>{subject}</Link>
                      </td>
                      <td>
                      {name} {job_name} ({d_name})
                      </td>

                      <td>
                      {category}
                      </td>
                      <td>
                      {date_created}
                      </td>
                    
                    </tr>)
                   
                  )
                )}
               
                
               {filterData.filter((item)=>(item.end_date !=="" && item.end_date !== null && item.end_date < moment(new Date()).format("YYYY-MM-DD")))
                .slice(offset, offset + limit)
                .map(
                  ({
                    b_no,bg_name,bg_no,
                    subject,name,job_name, d_name, 
                    date_created, category,
                    hit, is_notice
                  }) => (
                    is_notice !==1 &&( <tr key={b_no}>
                    

                      <td>
                      {b_no}
                      </td>
                    
                    {main && (
                       <td>
                      <span style={{border:"1px solid #ddd", padding:"2px"}}>{bg_name}</span>
                      </td>

                    )}
                     
                      <td>
                      <Link to={"/dataview/"+b_no} state={{boardName : bg_name, bg_no: bg_no}}>{subject}</Link>
                      </td>
                      <td>
                      {name} {job_name} ({d_name})
                      </td>
                      <td>
                      {category}
                      </td>

                      <td>
                      {date_created}
                      </td>
                  
                    </tr>)
                   
                  )
                )}

</tbody>
</table>
          <table>

            <colgroup>
              <col className="m_dp_n table_no" />
              <col className="" />
              <col className="ta_l" />
              <col className="m_dp_n table_date" />
            </colgroup>
            {/*<thead>
      <tr>
          <th className="m_dp_n table_no" scope="col">NO.</th>
          <th scope="col"></th>
          <th className="ta_l" scope="col">제목</th>
          <th className="m_dp_n table_date" scope="col">날짜</th>
      </tr>
  </thead>*/}
            <tbody>
             
            </tbody>
          </table>

          {filterData.length===0 && (
                <div style={{ textAlign: "center", padding:"50px 0", width:"100%", borderBottom:"1px solid #ddd" }}>
                  <p>게시물이 없습니다.</p>
                </div>
              )}

          <div style={{width:"100%",textAlign:"right"}}>
  {isloggedIn && (
    <Link to="write"
      style={{
        padding: "10px 30px",
        backgroundColor: "#555",
        color: "white",
        textAlign: "center",
        position:"relative",
        display:"inline-block",
        width:"105px",
        cursor: "pointer",
      }}
    >
      <HiPencilAlt/>글쓰기
    </Link>
  )}
  </div>
</div>



}



{filterData.length > 10 && (
  <div className="paging">
    <Pagination
      postsPerPage={limit}
      totalPosts={filterData.length}
      page={page}
      paginate={setPage}
    ></Pagination>
  </div>

)}


                  
                      </div>
                  </div>
          {/*</form>*/}
        </div>
      </div>
            
            
                
                
                

        </div>
     </div>
    </div>

  );
};

export default Board;
