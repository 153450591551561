import { useLocation } from "react-router-dom";
import moment from 'moment';
import React, { useState, useEffect, useRef } from "react";
import './css/docwrite.css';
import axios from "axios";

import LeaveHistoryUser from "../components/LeaveHistoryUser";


import { BiSearchAlt } from "react-icons/bi";

axios.defaults.withCredentials = true; 
const initialValues = {
  type:"",
  range:"",
  days:"",
  startDate:"",
  endDate:"",
  startTime:"",
  endTime:"",
  reason:"",
  contact:""
};

function TableRows({ rows, tableRowRemove, onValUpdate, user_no }) {
  const hour = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
  const week = ["일","월","화","수","목","금","토"];
  return rows.map((rowsData, index) => {

  

    const {vtype,
      vrange,
      start_time,
      end_time,
      start_date,
      end_date,
     
      days,
      reason,
      contact}= rowsData;
    return (
    <table className="leave_table" style={{width:"100%"}}>

        {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}
        
        
 

  

  <tr>

  <td class="table_title2" nowrap="">
    휴가 종류
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    {vtype==="1" && (
      <span>연차</span>
    )}
     {vtype==="0.5" && (
      <span>반차</span>
    )}
    {vtype==="0.25" && (
      <span>반반차</span>
    )}
      {vtype==="0" && (
      <span>공가</span>
    )}
     
     
    </div>
    </td>


    
<td class="table_title2 pc" nowrap="">
  범위선택
</td>
  <td class="table_list2_left pc">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>

  {vtype==="0.5" && (
    vrange==="2"? <span>오후 </span>: <span>오전 </span>
  )}

{vtype==="0.25" && (
    <span>{start_time}-{end_time}</span>
  )}
  </div>
  </td>


  <td class="table_title2 pc" nowrap="">
  휴가 기간 
</td>
  <td class="table_list2_left pc"> 
  
  {start_date !=="" && (
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
   
       {moment(start_date).format("yyyy-MM-DD")}({week[new Date(start_date).getDay()]})
<span>~</span>
{moment(end_date).format("yyyy-MM-DD")}({week[new Date(end_date).getDay()]})

  </div> 
  
  )}
  </td>

  <td class="table_title2 pc" nowrap="">
  차감기간
  </td>

  <td class="table_list2_left pc">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    

  {days * vtype}

  </div>
  </td>
  </tr>


<tr className="mobile">

<td class="table_title2" nowrap="">
  범위선택
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>

  {vtype==="0.5" && (
    vrange==="2"? <span>오후 </span>: <span>오전 </span>
  )}

{vtype==="0.25" && (
    <span>{start_time}-{end_time}</span>
  )}
  </div>
  </td>

</tr>

<tr className="mobile">

<td class="table_title2" nowrap="">
  휴가 기간 
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  {moment(start_date).format("yyyy-MM-DD")}({week[new Date(start_date).getDay()]})
<span>~</span>
{moment(end_date).format("yyyy-MM-DD")}({week[new Date(end_date).getDay()]})


  </div>
  </td>
</tr>



<tr className="mobile">
<td class="table_title2" nowrap="">
  차감기간
  </td>

  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    

  {days * vtype}

  </div>
  </td>

</tr>

<tr>
  <td class="table_title2" nowrap="">
    사유
  </td>
  <td class="table_list2_left">
  <div className="input-group">
  {reason}
  </div>
  </td>
    
  </tr>
  <tr>

<td class="table_title2" nowrap="">
  비상연락처
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  {contact}



  </div>
  </td>
</tr>

</table>
    );
  });
}
const LeaveView = ({onChange, docNo, userNo}) => {  
  const location = useLocation();
  const hour = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
  var todayDate = new Date().toISOString().slice(0, 10);
  console.log(todayDate);

  const [year, setYear] = useState();
  const [startDate, setStartDate] = useState("");

  const [info, setInfo]= useState([]);
  const [used, setUsed] = useState("");
  const [remain, setRemain] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [data, setData]= useState(initialValues);

  const [rows, initRow] = useState([]);

  const togglePopup = () => {
    setShowPopup(!showPopup);
};
  useEffect(()=>{

    let initial = [{
      vtype:'',
      vrange:'',
      start_time:'',
      end_time:'',
      start_date:'',
      end_date:'',
     
      days:'',
      reason:'',
      contact:''
    }];
    initRow(initial);

    axios
    .get("https://gw.thegmmedical.com:5003/api/getuser_leave/"+userNo)
    .then(({ data }) => {
      console.log("getuser_leave", data);
      setInfo(data[0]);

      let num = data[0].number;
      axios
        .get("https://gw.thegmmedical.com:5003/api/get_userleave/"+userNo, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {

          var history=data;
          var sum = 0;
          for (let i=0;i<history.length;i++){
            if (history[i].vtype !== "1"){
              sum+=parseFloat(history[i].vtype);
              console.log(history[i].vtype)
            }else{
              sum += parseInt(history[i].days);
            }
          }
          var day = Math.trunc(sum);
          var time = sum % 1.0;
          setUsed(day+"일 "+(time/0.25)*2+"시간");
          //console.log("...." + data);
          var remainDay = Math.trunc(num - sum);
          var remainTime = (num - sum) % 1.0;
          setRemain(remainDay+"일 "+(remainTime/0.25)*2+"시간")
          
          /*setInfo(data[0][0]);
          var history = data[1];
         
          }*/
        });
        

    });


        axios.get('https://gw.thegmmedical.com:5003/api/getleave/'+docNo)
        .then(function (response){
        
          if (response.data.length>0){
          setStartDate(response.data[0].start_date.substring(0,4));

          let year = response.data[0].start_date.slice(0,4);
          setYear(year);
          axios
    .get("https://gw.thegmmedical.com:5003/api/getuser_leave/"+userNo+"/"+year)
    .then(({ data }) => {
      console.log("getuser_leave", data);
      setInfo(data[0]);

      let num = data[0].number;
      axios
        .get("https://gw.thegmmedical.com:5003/api/get_userleave/"+userNo+"/"+year, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {

          var history=data;
          var sum = 0;
          for (let i=0;i<history.length;i++){
            if (history[i].vtype !== "1"){
              sum+=parseFloat(history[i].vtype);
              console.log(history[i].vtype)
            }else{
              sum += parseInt(history[i].days);
            }
          }
          var day = Math.trunc(sum);
          var time = sum % 1.0;
          setUsed(day+"일 "+(time/0.25)*2+"시간");
          //console.log("...." + data);
          var remainDay = Math.trunc(num - sum);
          var remainTime = (num - sum) % 1.0;
          setRemain(remainDay+"일 "+(remainTime/0.25)*2+"시간")
          
  
        });
        

    });



          initRow(response.data);
          }
        
        })
        .catch(function (error){
        console.log(error);
        })
        .then(function (){
        //always executed
        });
      }, []);
    
  

 
  return (
    <div className="wrapper">

    
    <div className="" style={{justifyContent:"flex-start"}}>


   <div className="leave" style={{height:"100%"}}>
      {/*{show ? (
    <DraftsList onChange={(value) => {setSubject(value.subject);editorRef.current?.getInstance().setHTML(value.content);setDraftNo(value.draft_no)}} closePopup={() => toggleShow()}/>
      ) : null}*/}
 
  
            {showPopup ? (
  <LeaveHistoryUser closePopup={() => togglePopup()} userno ={info.user_no} year={year}/>
) : null}
            
      <table className="write_table leave_view">
<tr>
<td>년도 : <span>
{startDate!==undefined && (
  startDate.substring(0,4)
)}  
  
  
</span></td>

<td><span>총 할당수 : </span><span> {info!==undefined && info.number}일</span></td>
<td><span>총 사용수 : </span><span> {used}</span><BiSearchAlt onClick={()=>togglePopup()}/></td>
<td><span>현재 잔여수 : </span><span> {remain}</span></td>
</tr>
        {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}
        
        
 

  
        <TableRows
    rows={rows}
  />
  
  
 
      </table>

 
      
    

      </div>
   </div>
</div>
  );
};

export default LeaveView;